import React, { useEffect } from 'react'
import Janus from '../../janus'
import adapter from "webrtc-adapter"
import { handleParticipantEvents, parseParticipants } from './helpers'
import PttInterface from './PttInterface'

const server = 'wss://ptt.firetext.net/ws'
const iceServers = [{ urls: "stun:stun.l.google.com:19302" }]

let janusRef

const ConferenceRoom = ({
    user, department, pluginHandle, roomId, webrtcUp, remoteStream, participants, roomJoined, audioEnabled,
    setPttJanusRef, setPttPluginHandle, setPttRoomId, setPttWebrtcUp, setPttRemoteStream, setPttParticipants, setPttRoomJoined,
    handlePttTouchUp, handlePttTouchDown, destroyJanusInstance
}) => {
    const opaqueId = `ptt-audiobridge-user-${user._id}`
    const room = "1234" //`ptt-audiobridge-room-${department._id}` // UNDO

    useEffect(() => {
        if (roomJoined) return

        const initializeJanus = () => {
            // console.log('init janus called')
            Janus.init({
                debug: 'all',
                dependencies: {
                    ...Janus.getDefaultDependencies(),
                    webRTCAdapter: adapter,
                },
                callback: () => {
                    // console.log('Janus initialized')
                    if (!Janus.isWebrtcSupported()) {
                        // console.log("No WebRTC support... ")
                        return
                    }

                    janusRef = new Janus({
                        server,
                        iceServers,
                        success: () => {
                            // console.log('Janus client initialized successfully')
                            setPttJanusRef(janusRef)
                            janusRef.attach({
                                plugin: "janus.plugin.ptt-audiobridge",
                                // plugin: "janus.plugin.audiobridge",
                                opaqueId: opaqueId,
                                success: (pluginHandle) => {
                                    // console.log("Plugin attached! (" + pluginHandle.getPlugin() + ", id=" + pluginHandle.getId() + ")")
                                    setPttPluginHandle(pluginHandle)
                                },
                                error: (error) => {
                                    console.error("Error attaching plugin...", error)
                                }
                            })
                        },
                        error: (error) => {
                            console.error('Error initializing Janus client:', error)
                        },
                        destroyed: () => {
                            // console.log('Janus instance destroyed')
                        }
                    })
                }

            })
        }

        initializeJanus()
    }, [opaqueId, roomJoined, setPttJanusRef, setPttPluginHandle])

    useEffect(() => {
        if (!pluginHandle)
            return

        if (!roomJoined) {
            const register = { request: "join", room, display: `${user.firstName} ${user.lastName}` }

            pluginHandle.send({
                message: register,
                success: (response) => {
                    // console.log("Joined conference room:", response)
                    setPttRoomJoined(true)
                },
                error: (error) => {
                    console.error("Error joining conference room...", error)
                }
            })

        }

        pluginHandle.consentDialog = (on) => {
            console.log('Consent dialog should be ' + (on ? 'on' : 'off') + ' now')
        }

        pluginHandle.iceState = (state) => {
            console.log('ICE state changed to ' + state)
        }

        pluginHandle.mediaState = (medium, on, mid) => {
            console.log('Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium + ' (mid=' + mid + ')')
        }

        pluginHandle.webrtcState = (on) => {
            console.log('Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now')
        }

        pluginHandle.onmessage = (msg, jsep) => {
            console.log('::: Got a message :::', msg)
            const event = msg['audiobridge']
            console.log('Event: ' + event)
            if (event) {
                if (event === 'joined') {
                    if (msg['id']) {
                        setPttRoomId(msg['id'])

                        // console.log('Successfully joined room ' + msg['room'] + ' with ID ' + msg['id'])

                        if (!webrtcUp) {
                            setPttWebrtcUp(true)

                            pluginHandle.createOffer(
                                {
                                    tracks: [
                                        { type: 'audio', capture: true, recv: true },
                                    ],
                                    customizeSdp: function (jsep) {
                                        jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1')
                                    },
                                    success: function (jsep) {
                                        // console.log('Got SDP!', jsep)
                                        var publish = { request: 'configure', muted: true }
                                        pluginHandle.send({ message: publish, jsep: jsep })
                                    },
                                    error: function (error) {
                                        Janus.error('WebRTC error:', error)
                                        // console.log('WebRTC error... ' + error.message)
                                    }
                                })
                        }
                    }

                    if (msg['participants']) {
                        // console.log('Got a list of participants:', msg['participants'])
                        const prtcpnts = parseParticipants(participants, msg['participants'])
                        setPttParticipants(prtcpnts)
                    }
                } else if (event === 'unmuted') { // some participant has unmuted
                    console.log('Participant unmuted:', msg['participant'])
                    if (msg['participant']) {
                        const prtcpnts = handleParticipantEvents(participants, 'unmuted', msg['participant'])
                        setPttParticipants(prtcpnts)
                    }

                } else if (event === 'muted') { // some participant has muted now
                    console.log('Participant muted:', msg['participant'])
                    const prtcpnts = handleParticipantEvents(participants, 'muted', msg['participant'])
                    setPttParticipants(prtcpnts)
                } else if (event === 'leaving') { //some participant left
                    console.log('Participant leaving:', msg['participant'])
                    const prtcpnts = handleParticipantEvents(participants, 'leaving', msg['participant'])
                    setPttParticipants(prtcpnts)
                } else if (event === "roomchanged") {
                    console.log('Participant roomchanged:', msg['participant'])
                    setPttRoomId(msg['id'])

                    // console.log("Moved to room " + msg["room"] + ", new ID: " + msg['id'])

                    if (msg["participants"]) {
                        // console.log("Got a list of participants:", msg["participants"])
                        const prtcpnts = parseParticipants(participants, msg['participants'])
                        setPttParticipants(prtcpnts)

                    }
                } else if (event === "destroyed") {
                    // console.log("The room has been destroyed!")

                    setPttRoomId(null)
                    setPttParticipants([])
                    setPttWebrtcUp(false)

                } else if (event === "event") {
                    if (msg["participants"]) {
                        // console.log("Got a list of participants:", msg["participants"])
                        const prtcpnts = parseParticipants(participants, msg['participants'])
                        setPttParticipants(prtcpnts)

                    } else if (msg["error"]) {
                        if (msg["error_code"] === 485) {
                            // console.log('Room not found')
                        } else {
                            console.error(msg["error_code"], msg["error"])
                        }
                        return
                    }
                }
            }
            if (jsep) {
                // console.log("Handling SDP as well...", jsep)
                pluginHandle.handleRemoteJsep({ jsep: jsep })
            }
        }

        pluginHandle.onlocaltrack = (track, on) => {
            // console.log("Local track " + (on ? "added" : "removed") + ":", track)
        }

        pluginHandle.onremotetrack = (track, mid, on) => {
            // console.log("Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":", track)

            if (remoteStream || track.kind !== "audio")
                return

            if (!on) {
                if (remoteStream) {
                    try {
                        const tracks = remoteStream.getTracks()
                        for (const i in tracks) {
                            const mst = tracks[i]
                            if (mst)
                                mst.stop()
                        }
                    } catch (e) { }
                }

                setPttRemoteStream(null)
                return
            }
            const rs = new MediaStream([track])
            setPttRemoteStream(rs)

        }

        pluginHandle.oncleanup = () => {
            // console.log(" ::: Got a cleanup notification :::")
            // setPttRoomId(null)
            // setPttParticipants([])
            // setPttWebrtcUp(false)
            // setPttPluginHandle(null)
        }

    }, [participants, pluginHandle, remoteStream, room, roomJoined, setPttParticipants, setPttPluginHandle, setPttRemoteStream, setPttRoomId, setPttRoomJoined, setPttWebrtcUp, user.firstName, user.lastName, webrtcUp])


    return (
        <>
            <PttInterface
                departmentName={department.name}
                audioEnabled={audioEnabled}
                participants={participants}
                handlePttTouchDown={handlePttTouchDown}
                handlePttTouchUp={handlePttTouchUp}
                destroyJanusInstance={destroyJanusInstance}
            />
        </>

    )
}

export default ConferenceRoom;