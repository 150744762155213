import React, { Component } from 'react'

// Material UI
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, withStyles, CircularProgress } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { withSnackbar } from 'notistack'

// Utilities
import axios from "axios"
import Fuse from 'fuse.js'
import theme from "../../utilities/theme"
import { handleInputChange, handleErrors } from "../../utilities/handleChange"
import { OpenSnackBar } from '../../utilities/handleSnackBar'

// Components
import GeneralTextField from "../general/TextField"
import CreateCadDepartmentModal, { cadMap } from './createCadDepartmentModal'
import { ValidateAndTransformEmail } from '../../utilities/Validate'
import EditCadDepartmentModal from './editCadDepartmentModal'

const initialState = {
    cadDepartments: [],
    info: {
        search: ''
    },
    filteredCadDepartments: [],
    departments: [],
    cadDepartment: {
        email: '',
        departmentId: null,
        cadType: null
    },
    errors: {
        cadDepartment: {
            email: null,
            departmentId: null,
            cadType: null
        },
        info: {

        }
    },
    modals: {
        createCadDepartment: false,
        updateCadDepartment: false
    },
    loaders: {
        getCadDepartments: false,
        getDepartments: false,
        createCadDepartment: false,
        updateCadDepartment: false,
        deleteCadDepartment: false
    }
}

class CadDepartments extends Component {
    state = initialState
    // Life cycle functions
    componentDidMount = async () => {
        await this.getCadDepartments()
        await this.getDepartments()
    }

    componentWillUnmount = () => {
        this.props?.closeSnackbar?.()
    }

    // General
    handleErrors = () => {
        const { cadDepartment } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'cadDepartment.email',
                type: 'string',
                shouldCheck: true,
                validation: (!!ValidateAndTransformEmail(cadDepartment.email) && cadDepartment.email?.includes('@parse.firetext.net')),
                validationMessage: "Provide valid email ending with @parse.firetext.net."
            },
            {
                path: 'cadDepartment.departmentId',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'cadDepartment.cadType',
                type: 'string',
                shouldCheck: true
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleSearchInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => {
            this.searchCadDepartments()
        })
    }

    handleModal = (modal, extra) => {
        const { modals } = this.state
        switch (modal) {
            case 'createCadDepartment':
                if (modals.createCadDepartment) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        cadDepartment: initialState.cadDepartment,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        errors: initialState.errors
                    }))
                }
            case 'updateCadDepartment':
                if (modals.updateCadDepartment) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, updateCadDepartment: false },
                        cadDepartment: initialState.cadDepartment,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, updateCadDepartment: true },
                        cadDepartment: extra
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    searchCadDepartments = () => {
        const { search } = this.state.info
        const { cadDepartments } = this.state

        if (search && search.length > 0) {
            const fuseResult = new Fuse(cadDepartments, {
                keys: [
                    { name: 'email', weight: 0.8 },
                    { name: 'cadType', weight: 0.8 },
                ],
                threshold: .5,
                includeMatches: true
            })
            let filteredCadDepartments = fuseResult.search(search).map(resultObject => {
                return {
                    ...resultObject.item,
                }
            })

            this.setState({ filteredCadDepartments })
        } else {
            this.setState({
                filteredCadDepartments: [...cadDepartments]
            })
        }
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ""
            }
        }), this.searchCadDepartments)
    }

    // CRUD
    getDepartments = async () => {
        const { loaders, info } = this.state

        if (!loaders.getDepartments) {

            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            try {
                const { accessToken } = this.props
                const { page, limit, search } = info
                const config = {
                    headers: {
                        Authorization: accessToken
                    },
                    params: {
                        page,
                        limit,
                        search
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments', config)
                // console.log({ getDepartmentsResponse })
                if (getDepartmentsResponse.data.success) {

                    let departments = getDepartmentsResponse.data.departments
                        .map(dept => ({
                            ...dept,
                            value: dept._id,
                            text: `${dept.name} ${dept.agency ?? ''}`
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name))

                    this.setState(prevState => ({
                        departments,
                        loaders: { ...prevState.loaders, getDepartments: false }
                    }))

                    return
                }
            } catch (error) {
                // console.log({ 'getDepartments error': error })
            }

            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: false } }))
        }
    }

    getCadDepartments = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getCadDepartments) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getCadDepartments: true } }))
            try {
                const getCadDepartmentsResponse = await axios.get('/api/cadDepartments', config)
                if (getCadDepartmentsResponse && getCadDepartmentsResponse.data && getCadDepartmentsResponse.data.success) {
                    let cadDepartments = getCadDepartmentsResponse.data.cadDepartments
                    this.setState(prevState => ({
                        cadDepartments,
                        filteredCadDepartments: cadDepartments,
                        loaders: { ...prevState.loaders, getCadDepartments: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getCadDepartmentsResponse.data.errors },
                        loaders: { ...prevState.loaders, getCadDepartments: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getCadDepartments: false }
                }))
            }
        }
    }

    createCadDepartment = async () => {
        let { loaders, cadDepartment } = this.state

        if (!loaders.createCadDepartment) {

            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createCadDepartment: true } }))
                const { accessToken } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const createCadDepartmentResponse = await axios.post('/api/cadDepartments', { cadDepartmentInformation: { ...cadDepartment } }, config)
                    if (createCadDepartmentResponse && createCadDepartmentResponse.data && createCadDepartmentResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createCadDepartment: false }
                        }))
                        this.handleModal('createCadDepartment')
                        this.getCadDepartments()
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "CAD Department created successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createCadDepartmentResponse.data.errors },
                            loaders: { ...prevState.loaders, createCadDepartment: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createCadDepartment: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateCadDepartment = async (type) => {
        const { cadDepartment, filteredCadDepartments, loaders } = this.state

        if (!loaders.updateCadDepartment) {
            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateCadDepartment: true } }))

                let formattedCadDepartment = {
                    ...cadDepartment
                }

                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                try {
                    let updateCadDepartmentResponse = await axios.put(`/api/cadDepartments/${cadDepartment._id}`, formattedCadDepartment, config)

                    if (updateCadDepartmentResponse.data.success) {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, updateCadDepartment: false } }))
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "CAD Department updated successfully", "success")
                        let updatedCadDepartments = filteredCadDepartments.map(cadDepartmentObject => {
                            if (cadDepartmentObject._id === cadDepartment._id) {

                                return {
                                    ...cadDepartment,
                                    ...(updateCadDepartmentResponse.data?.cadDepartment ?? {})
                                }
                            } else {
                                return cadDepartmentObject
                            }
                        })
                        this.setState(prevState => ({
                            cadDepartments: updatedCadDepartments,
                            filteredCadDepartments: updatedCadDepartments
                        }), () => this.getCadDepartments())
                        return true
                    } else {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateCadDepartment: false },
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, updateCadDepartment: false },
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }

        }
    }

    deleteCadDepartment = async () => {
        const { loaders, cadDepartment } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteCadDepartment) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteCadDepartment: true } }))
            try {
                const deleteCadDepartmentResponse = await axios.delete(`/api/cadDepartments/${cadDepartment._id}`, config)
                if (deleteCadDepartmentResponse && deleteCadDepartmentResponse.data && deleteCadDepartmentResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteCadDepartment: false }
                    }))
                    this.handleModal('updateCadDepartment')
                    this.getCadDepartments()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "CAD Department deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteCadDepartmentResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteCadDepartment: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteCadDepartment: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    render() {
        const { cadDepartment, departments, errors, modals, loaders, info, filteredCadDepartments } = this.state
        return (
            <Grid
                style={{
                    margin: 12
                }}
            >
                {modals.createCadDepartment ?
                    <CreateCadDepartmentModal
                        cadDepartment={cadDepartment}
                        departments={departments}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createCadDepartment}
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('createCadDepartment')}
                        createCadDepartment={this.createCadDepartment}
                    />
                    :
                    null
                }
                {modals.updateCadDepartment ?
                    <EditCadDepartmentModal
                        cadDepartment={cadDepartment}
                        departments={departments}
                        errors={errors}
                        loaders={loaders}
                        open={modals.updateCadDepartment}
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('updateCadDepartment')}
                        updateCadDepartment={this.updateCadDepartment}
                        deleteCadDepartment={this.deleteCadDepartment}
                    />
                    :
                    null
                }
                <Grid
                    container
                    style={{ display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8, borderRadius: 0, marginBottom: 12 }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createCadDepartment')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add CAD Department
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex' }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search CAD Departments...'
                            statePath={`info.search`}
                            value={info.search}
                            handleInputChange={this.handleSearchInputChange}
                            resetSearch={this.resetSearch}
                        />
                    </Grid>
                </Grid>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    style={{ borderRadius: 0, marginBottom: theme.spacing(8) }}
                >
                    <Table
                        style={{ minWidth: 650 }}
                        aria-label='CAD Departments'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    CAD Email
                                </TableCell>
                                <TableCell>
                                    Department
                                </TableCell>
                                <TableCell>
                                    CAD Type
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaders.getCadDepartments &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: theme.spacing(12), paddingBottom: theme.spacing(12) }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <CircularProgress
                                            size={36}
                                        />

                                    </TableCell>
                                </TableRow>
                            }
                            {!loaders.getCadDepartments && filteredCadDepartments.map((cadDepartment) =>
                                <TableRow
                                    key={cadDepartment._id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.handleModal('updateCadDepartment', cadDepartment)}
                                >
                                    <TableCell
                                        component='th'
                                    >
                                        {cadDepartment.email}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {cadDepartment.departmentObject?.name}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {cadMap[cadDepartment.cadType]?.text}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(CadDepartments))