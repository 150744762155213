// React
import React from 'react'
// Material UI
import { Grid, withStyles, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

class RadioGroupField extends React.Component {
    handleChange = (e) => {
        const { statePath, handleInputChange, isValueString, isValueBool } = this.props
        let value = (isValueString) ? e.target.value : (isValueBool) ? e.target.value === 'true' : parseInt(e.target.value, 10)

        handleInputChange(this.props, { value, statePath })
    }

    render() {
        const { name, value, options, labelPlacement } = this.props

        return (
            <RadioGroup
                row
                aria-label={name}
                name={name}
                value={value.toString()}
            >
                <Grid
                    container
                    spacing={4}
                >
                    {
                        options.filter(option => option.invisible !== true).map(option => (
                            <FormControlLabel
                                value={option.value.toString()}
                                control={<Radio color="primary" size="small" style={{ padding: 16, marginLeft: 16 }} />}
                                label={option.key}
                                key={option.key}
                                labelPlacement={labelPlacement || "end"}
                                onChange={e => this.handleChange(e)}//{ () => this.setState(prevState => ({ user: { ...prevState.user, alerts: {...prevState.user.alerts, dispatch: {...prevState.user.alerts.dispatch, type: 2 } } } })) }
                                disabled={option.disabled || false}
                            />
                        ))
                    }
                </Grid>
            </RadioGroup>
        )
    }
}

export default withStyles(null, { withTheme: true })(RadioGroupField)
