import React, { useEffect, useState } from 'react'
// Material UI
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, CircularProgress, Tooltip, Button, Typography } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
// Component
import TablePagination from '../../general/TablePagination'
// Utilities
import moment from 'moment'
import { maskPhoneNumber } from '../../../utilities/Helpers'
import { sortTableData } from '../../../utilities/sorthelper'
import ToggleIconButton from '../../../utilities/togglebutton'
import UserRegistrationRequestDialog from './UserRegistrationRequestDialog'

const UsersTable = (props) => {

    const { users, loaders, info, noUsersPlaceHolderText, handleChangePage, handleChangeRowsPerPage, handleModal, resetSearch, resetFilters, checkIfFiltersSelected, selectedDepartmentId, handleAccpetOrDeclineRegistrationRequest, pendingRegistrationRequestLoading } = props
    const { pagination, limit, page, search } = info
    // adding sorting function
    const [sortedColumn, setSortedColumn] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [sortedUsers, setSortedUsers] = useState(users)
    const [showHandlePendingRegistrationDialog, setShowHandlePendingRegistrationDialog] = useState(false)

    useEffect(() => {
        setSortedUsers(sortTableData(users, sortedColumn, sortOrder)) // Update sortedUsers whenever users change
    }, [users, sortOrder, sortedColumn])

    const handleColumnHeaderClick = (column) => {
        const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc'
        setSortedColumn(column)
        setSortOrder(newSortOrder)
    }

    const handlePedingRegistrationRequest = (user) => {
        setShowHandlePendingRegistrationDialog(user)
    }

    return (
        <>
            {showHandlePendingRegistrationDialog &&
                <UserRegistrationRequestDialog
                    open={showHandlePendingRegistrationDialog}
                    onClose={() => setShowHandlePendingRegistrationDialog(false)}
                    user={showHandlePendingRegistrationDialog}
                    onProcessRequest={handleAccpetOrDeclineRegistrationRequest}
                    loading={pendingRegistrationRequestLoading}
                />
            }
            <TableContainer
                component={Paper}
                elevation={0}
                style={{
                    borderRadius: 0,
                    marginBottom: 64,
                    display: 'flex',
                    flex: 1
                }}
            >
                <Table
                    aria-label='Users'
                >
                    <TablePagination
                        showAtTop={true}
                        total={pagination.total}
                        rowsPerPage={limit}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                First Name
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('firstName')}
                                    selected={sortedColumn === 'firstName'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>
                            <TableCell>
                                Last Name
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('lastName')}
                                    selected={sortedColumn === 'lastName'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>
                            <TableCell>
                                Email
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('email')}
                                    selected={sortedColumn === 'email'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>
                            <TableCell>
                                Phone
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('phone')}
                                    selected={sortedColumn === 'phone'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>
                            <TableCell>
                                Type
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('type')}
                                    selected={sortedColumn === 'type'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>
                            <TableCell>
                                Date Created
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('createdAt')}
                                    selected={sortedColumn === 'createdAt'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell
                                align='right'
                            >

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loaders.getUsers &&
                            sortedUsers.map((user) => (
                                <TableRow
                                    key={user._id}
                                    onClick={() => handleModal('editUser', user)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component='th'
                                    >
                                        {user.firstName}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {user.lastName}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {user.email}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {maskPhoneNumber(user.phone)}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {user.type === 'user' ? (user.departments.find(dept => dept.departmentId === selectedDepartmentId)?.role === 'departmentAdmin' ? 'Department Admin' : 'Subscriber') : user.type.charAt(0).toUpperCase() + user.type.slice(1)}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {moment(user.createdAt).format('MM/DD/YYYY')}
                                    </TableCell>
                                    <TableCell
                                        align='right'
                                    >
                                        {user.departmentPendingRegistrationRequests?.find(dept => dept.departmentId === selectedDepartmentId)?.departmentId ?
                                            <Button
                                                type="button"
                                                variant='contained'
                                                size='small'
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handlePedingRegistrationRequest(user)
                                                }}
                                            >
                                                Pending
                                            </Button>
                                            :
                                            user.deactivated ?
                                                <Tooltip
                                                    title='User was deactivated'
                                                >
                                                    <div
                                                        style={{ width: 20, height: 20, background: '#ff0000', borderRadius: '50%' }}
                                                    />
                                                </Tooltip>
                                                :
                                                !user.deactivated && user.activated ?
                                                    <Tooltip
                                                        title='User is active'
                                                    >
                                                        <div
                                                            style={{ width: 20, height: 20, background: '#00b300', borderRadius: '50%' }}
                                                        />
                                                    </Tooltip>
                                                    :
                                                    !user.activated &&
                                                    <Tooltip
                                                        title='User has not completed account creation'
                                                    >
                                                        <div
                                                            style={{ width: 20, height: 20, background: 'yellow', borderRadius: '50%' }}
                                                        />
                                                    </Tooltip>
                                        }
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                        align='right'
                                        width='10'
                                    >
                                        <IconButton
                                            onClick={() => handleModal('editUser', user)}
                                            size='small'
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {loaders.getUsers &&
                            <TableRow>
                                <TableCell
                                    style={{ paddingTop: 96, paddingBottom: 96 }}
                                    colSpan={6}
                                    align="center"
                                >
                                    <CircularProgress
                                        size={36}
                                    />

                                </TableCell>
                            </TableRow>
                        }
                        {(!users || !users.length) && !loaders.getUsers &&
                            <TableRow>
                                <TableCell
                                    style={{ paddingTop: 96, paddingBottom: 96 }}
                                    colSpan={6}
                                    align="center"
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        {noUsersPlaceHolderText || 'No users were found.'}
                                    </Typography>
                                    {search && search.length && resetSearch &&
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => resetSearch()}
                                        >
                                            Reset Search
                                        </Button>
                                    }
                                    {resetFilters && checkIfFiltersSelected() &&
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => resetFilters()}
                                        >
                                            Reset Filters
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    {pagination && info && handleChangePage && handleChangeRowsPerPage &&
                        <TablePagination
                            total={pagination.total}
                            rowsPerPage={limit}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    }
                </Table>
            </TableContainer>
        </>
    )
}


export default UsersTable;