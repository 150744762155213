// Material UI
import { Grid, Typography } from '@material-ui/core'
// Components
import GeneralTextField from '../../../general/TextField'
import GeneralDropdownField from '../../../general/DropdownField'
import GeneralCheckboxField from '../../../general/CheckboxField'
// Utilities
import { ChromePicker } from 'react-color'
import theme from '../../../../utilities/theme'
import { StateOptions } from '../../../../utilities/info'
import { StatusTypes, DepartmentTypes, Timezones, SmsNumbers } from '../../../../utilities/Options'
import { filterDepartments } from '../utils'

const InfoTab = ({
    handleInputChange,
    department, errors, update,
    handleColorChange, departmentOptions,
}) => {
    return (
        <Grid
            xs={12}
            spacing={2}
            container
        >
            <Grid
                xs={12}
                container
                item
            >
                <Typography
                    variant='h6'
                    style={{ marginBottom: 8 }}
                >
                    Basic Info
                </Typography>
            </Grid>

            <GeneralTextField
                autoFocus={true}
                width={4}
                label='Name *'
                placeholder='Enter department name...'
                statePath={`department.name`}
                value={department.name}
                error={errors.department.name}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                autoFocus={true}
                width={4}
                label='Agency'
                placeholder='Enter agency details...'
                statePath={`department.agency`}
                value={department.agency}
                error={errors.department.agency}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                width={4}
                format='phoneNumber'
                label='Phone Number'
                placeholder={`Enter department's phone number...`}
                statePath={`department.phone`}
                value={department.phone}
                error={errors.department.phone}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                width={4}
                label='FDID'
                placeholder='Enter Fdid...'
                statePath={`department.fdid`}
                value={department.fdid}
                handleInputChange={handleInputChange}
            />
            <GeneralDropdownField
                options={DepartmentTypes}
                format='autocomplete'
                width={4}
                label='Type *'
                placeholder='Enter department type...'
                statePath={`department.type`}
                value={department.type}
                error={errors.department.type}
                handleInputChange={handleInputChange}
            />
            <GeneralDropdownField
                options={Timezones}
                format='autocomplete'
                width={4}
                label='Timezone *'
                placeholder='Enter department timezone...'
                statePath={`department.timezone`}
                value={department.timezone}
                error={errors.department.timezone}
                handleInputChange={handleInputChange}
            />
            {update &&
                <>
                    <GeneralDropdownField
                        options={StatusTypes}
                        width={4}
                        label='Status *'
                        placeholder='Select status...'
                        statePath={`department.activated`}
                        value={department.activated}
                        error={errors.department.activated}
                        handleInputChange={handleInputChange}
                    />
                </>
            }
            <GeneralTextField
                width={6}
                label='Address Line 1 *'
                placeholder='Enter address line 1...'
                statePath={`department.location.address.addressLine1`}
                value={department.location.address.addressLine1}
                error={errors.department.location.address.addressLine1}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                width={6}
                label='Address Line 2'
                placeholder='Enter address line 2...'
                statePath={`department.location.address.addressLine2`}
                value={department.location.address.addressLine2}
                error={errors.department.location.address.addressLine2}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                width={3}
                label='City *'
                placeholder='Enter city...'
                statePath={`department.location.address.city`}
                value={department.location.address.city}
                error={errors.department.location.address.city}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                width={3}
                label='County'
                placeholder='Enter county...'
                statePath={`department.location.address.county`}
                value={department.location.address.county}
                error={errors.department.location.address.county}
                handleInputChange={handleInputChange}
            />
            <GeneralDropdownField
                options={StateOptions}
                format='autocomplete'
                width={3}
                label='State *'
                placeholder='Enter state...'
                statePath={`department.location.address.state`}
                value={department.location.address.state}
                error={errors.department.location.address.state}
                handleInputChange={handleInputChange}
            />
            <GeneralTextField
                width={3}
                label='Zip Code *'
                placeholder='Enter zip code...'
                statePath={`department.location.address.zip`}
                value={department.location.address.zip}
                error={errors.department.location.address.zip}
                handleInputChange={handleInputChange}

            />

            <Grid xs={12} />

            <GeneralCheckboxField
                gridStyle={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                width={6}
                size='small'
                label='Use custom SMS number for this department?'
                statePath={`department.useCustomSmsNumber`}
                value={department.useCustomSmsNumber}
                error={errors.department.useCustomSmsNumber}
                handleInputChange={handleInputChange}
            />
            {department.useCustomSmsNumber ?
                <GeneralTextField
                    width={4}
                    format='phoneNumber'
                    label='Sms Number *'
                    placeholder={`Enter department's phone number...`}
                    statePath={`department.smsNumber`}
                    value={department.smsNumber.replace('+1', '')}
                    error={errors.department.smsNumber}
                    handleInputChange={(e, data) => handleInputChange(e, { ...data, value: `+1${data.value}` })}
                />
                : <GeneralDropdownField
                    options={SmsNumbers}
                    width={3}
                    label='Sms Number *'
                    statePath={`department.smsNumber`}
                    value={department.smsNumber}
                    error={errors.department.smsNumber}
                    handleInputChange={handleInputChange}
                />}

            <GeneralCheckboxField
                gridStyle={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                width={6}
                size='small'
                label='Is this an all call?'
                statePath={`department.allCall`}
                value={department.allCall}
                error={errors.department.allCall}
                handleInputChange={handleInputChange}
            />
            {department.allCall &&
                <GeneralDropdownField
                    options={departmentOptions.map(dept => (department._id === dept.value || department.allCallDepartmentIds.includes(dept.value)) ? { ...dept, disabled: true } : dept)}
                    format='multiple'
                    width={12}
                    label='All Call Departments'
                    placeholder='Select all call departments...'
                    statePath={`department.allCallDepartmentIds`}
                    value={filterDepartments(departmentOptions, department.allCallDepartmentIds)}
                    handleInputChange={handleInputChange}
                />
            }
            <div
                style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 12, marginBottom: 12, justifyContent: 'center', alignItems: 'center' }}
            >
                <Typography
                    variant='h6'
                    style={{ marginBottom: 24 }}
                >
                    Department Color
                </Typography>
                <div
                    style={{ display: 'flex', marginRight: 'auto', marginLeft: 'auto' }}
                >
                    <ChromePicker
                        color={department.color || theme.palette.primary.main}
                        onChangeComplete={handleColorChange}
                    />
                    <div
                        style={{
                            display: 'flex',
                            margin: 'auto',
                            height: 128,
                            width: 296,
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.light,
                            padding: 40,
                            background: department.color
                        }}
                    >
                        <Typography>
                            {department.name}
                        </Typography>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default InfoTab;
