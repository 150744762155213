// React
import React, { useEffect } from 'react'
import { Prompt } from "react-router-dom"

// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Components
import GeneralTextField from '../general/TextField'
import GeneralDropdownField from '../general/DropdownField'

export const cadMap = {
    caliber: {
        text: 'Caliber Public Safety',
        value: 'caliber'
    },
    cushing: {
        text: 'Cushing Systems',
        value: 'cushing'
    },
    rps: {
        text: 'Relativity Public Safety (RPS)',
        value: 'rps'
    }
}
export const cadTypeOptions = [
    {
        text: cadMap.caliber.text,
        value: cadMap.caliber.value
    },
    {
        text: cadMap.cushing.text,
        value: cadMap.cushing.value
    },
    {
        text: cadMap.rps.text,
        value: cadMap.rps.value
    }
]

const CreateCadDepartmentModal = (props) => {
    const { handleInputChange, createCadDepartment, cadDepartment, departments, errors, loaders, open } = props

    useEffect(() => {
        const beforeunload = (e) => {
            e.preventDefault()
            e.returnValue = true
        }

        window.addEventListener("beforeunload", beforeunload)
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        }
    }, [])

    const handleClose = () => {
        let shouldCloseModal = window.confirm(
            "Changes you made may not be saved. Are you sure you want to leave this page?"
        )
        if (!shouldCloseModal) return
        else props.handleClose()
    }


    return (
        <>
            <Prompt
                when={true}
                message={() => "Changes you made may not be saved. Are you sure you want to leave this page?"}
            />
            <Dialog
                onClose={!loaders.createCadDepartment && handleClose}
                open={open}
                maxWidth='md'
                PaperProps={{ style: { flex: 1 } }}
            >
                <DialogTitle
                    disableTypography
                    style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Typography
                        variant='h6'
                    >
                        Add New CAD Department
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        disabled={loaders.createCadDepartment}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                >
                    <Grid
                        xs={12}
                        spacing={2}
                        container
                    >
                        <GeneralTextField
                            autoFocus={true}
                            width={12}
                            label='CAD Email *'
                            placeholder='Enter email for CAD integration...'
                            statePath={`cadDepartment.email`}
                            value={cadDepartment.email}
                            error={errors.cadDepartment.email}
                            handleInputChange={handleInputChange}
                        />

                        <GeneralDropdownField
                            options={departments}
                            format="autocomplete"
                            width={12}
                            label={`Department *`}
                            placeholder='Select department...'
                            statePath={`cadDepartment.departmentId`}
                            value={cadDepartment.departmentId}
                            handleInputChange={handleInputChange}
                            error={errors.cadDepartment?.departmentId}
                        />

                        <GeneralDropdownField
                            options={cadTypeOptions}
                            format="autocomplete"
                            width={12}
                            label={`CAD Type *`}
                            placeholder='Select CAD type...'
                            statePath={`cadDepartment.cadType`}
                            value={cadDepartment.cadType}
                            handleInputChange={handleInputChange}
                            error={errors.cadDepartment?.cadType}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={createCadDepartment}
                        color='secondary'
                        variant='contained'
                    >
                        {loaders.createCadDepartment &&
                            <CircularProgress
                                size={18}
                                style={{ marginRight: 4 }}
                            />
                        }
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateCadDepartmentModal;
