// React
import React, { useEffect, useState } from 'react'
// React Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Grid, CircularProgress, Typography } from '@material-ui/core'
// Utils
import axios from 'axios'
import moment from 'moment'
// Components
import DispatchAudioPlayer from './audio-player/NewDispatchAudioPlayer'
import theme from '../../utilities/theme'
import LogoIcon from '../../assets/logo.png'

const mimeCodec = 'audio/webm; codecs="opus";'
const audioStreamSupported = 'MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)

function isOlderThanNMinutes(n, timestamp) {
    const start = moment(timestamp)
    let end = moment() //now
    let duration = moment.duration(end.diff(start))
    let minutes = duration.asMinutes()
    return minutes > n
}

const PublicAudioDispatchPlayer = (props) => {
    const { showSideBar } = props
    // eslint-disable-next-line no-unused-vars
    const [dispatchId, setDispatchId] = useState(props.match.params.dispatchId)
    const [dispatch, setDispatch] = useState(null)
    const [dispatchLoader, setDispatchLoader] = useState(false)

    const getDispatch = async () => {
        if (!dispatchLoader) {
            setDispatchLoader(true)
            try {
                const getDispatchRespone = await axios.get(`/api/dispatches/publicAudioDispatch?dispatchId=${dispatchId}`)
                if (getDispatchRespone.data.success) {
                    setDispatchLoader(false)
                    let dispatchObject = { ...getDispatchRespone.data.dispatch }
                    setDispatch(dispatchObject)
                    return dispatchObject
                }
            } catch (e) {
                alert(e?.response?.data?.errors?.[0]?.message || 'Failed to get dispatch.')
            }
            setDispatchLoader(false)
        }
    }

    useEffect(() => {
        let interval
        const asyncCall = async () => {
            if (dispatchId) {
                let dispatch = await getDispatch()
                if (dispatch && dispatch._id && !isOlderThanNMinutes(5, dispatch.createdAt) && !dispatch.voiceUrl) {
                    if (!audioStreamSupported) {
                        console.error('Unsupported MIME type or codec: ', mimeCodec)
                        let retries = 0
                        interval = setInterval(async () => {
                            if (retries++ <= 3) {
                                let dispatch = await getDispatch()
                                if (dispatch?.voiceUrl) clearInterval(interval)
                            } else {
                                clearInterval(interval)
                            }
                        }, 30000)
                    }
                }
            }
        }
        asyncCall()

        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (dispatchLoader) return (
        <Grid
            container
            style={{ background: theme.palette.primary.dark, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: '100vh', marginLeft: showSideBar ? -125 : 0 }}
        >
            <CircularProgress
                size={36}
                style={{ color: theme.palette.primary.light }}
            />
        </Grid>
    )

    return (
        <Grid
            container
            item
            style={{ background: theme.palette.primary.dark, display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', marginLeft: showSideBar ? -125 : 0 }}
        >
            <Grid
                item
                style={{
                    marginTop: 64,
                    marginBottom: 64,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    style={{
                        height: 60,
                        marginRight: 8,
                        backgroundColor: theme.palette.primary.background,
                        color: theme.palette.primary.dark
                    }}
                    src={LogoIcon}
                    alt="FireTEXT"
                />
                <h1
                    style={{ fontSize: 40, color: theme.palette.primary.light }}
                >
                    FireTEXT<sup style={{ fontSize: 18, position: 'relative', top: -12 }}>®</sup>
                </h1>

            </Grid>
            {dispatch && (dispatch?.voiceUrl || audioStreamSupported) ?
                <Grid
                    item
                    style={{
                        marginTop: 64,
                        marginBottom: 64,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {dispatch?.department?.name ?
                        <Typography
                            variant='body1'
                            style={{ color: theme.palette.primary.light }}
                        >
                            Department {dispatch.department.name}
                        </Typography>
                        :
                        null
                    }
                    <DispatchAudioPlayer
                        dispatch={dispatch}
                        isPublicView={true}
                    />
                    {
                        dispatch.transcriptionText ?
                            <Typography
                                variant='body2'
                                style={{ color: theme.palette.primary.light, margin: '0 10px' }}
                            >
                                <b>Transcription</b>{`: ${dispatch.transcriptionText}`}
                            </Typography>
                            :
                            null
                    }
                </Grid>
                :
                dispatch && (!audioStreamSupported) ?
                    <Typography
                        variant='body1'
                        style={{ padding: 16, color: theme.palette.primary.light }}
                    >
                        Once this dispatch is finished recording, it will be playable on this page. Unfortunately, audio live streaming is not fully supported on your device yet.
                    </Typography>
                    :
                    null
            }

        </Grid>
    )

}

export default withRouter(PublicAudioDispatchPlayer)