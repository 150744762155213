export const escapeXmlTags = (value) => {
    if (value) {
        var escapedValue = value.replace(new RegExp('<', 'g'), '&lt')
        escapedValue = escapedValue.replace(new RegExp('>', 'g'), '&gt')
        return escapedValue
    }
}

export const parseParticipants = (existingParticipants, newParticipants) => {
    const existingMap = new Map(existingParticipants.map(participant => [participant.id, participant]))

    newParticipants
        .forEach(np => {
            const newParticipant = {
                id: np["id"],
                display: escapeXmlTags(np["display"]),
                setup: np["setup"],
                muted: np["muted"],
                spatial: np["spatial_position"]
            }
            existingMap.set(newParticipant.id, newParticipant)
        })

    return Array.from(existingMap.values())
}

export const handleParticipantEvents = (participants, event, participant) => {
    const updatedParticipants = []

    if (event === 'unmuted') {
        for (const p of participants) {
            if (p.id === participant.id) {
                updatedParticipants.push({
                    ...p,
                    muted: false
                })
            }
            else updatedParticipants.push(p)
        }
    }
    else if (event === 'muted') {
        for (const p of participants) {
            if (p.id === participant.id) {
                updatedParticipants.push({
                    ...p,
                    muted: true
                })
            }
            else updatedParticipants.push(p)
        }
    }
    else if (event === 'leaving') {
        for (const p of participants) {
            if (p.id !== participant.id) {
                updatedParticipants.push(p)
            }
        }
    }

    return updatedParticipants
}