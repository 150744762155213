import React, { useState, useEffect, useRef } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
    CircularProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import moment from 'moment'

// This component will display a modal with an audio player for the selected recording
const RecordingPlayer = ({ open, onClose, recording }) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    // Create a ref for the audio element to manage it directly
    const audioRef = useRef(null)

    // Format recording name from filename
    const formatRecordingName = (fileName) => {
        // Remove file extension
        const nameWithoutExt = fileName.replace(/\.[^/.]+$/, "")

        // Parse date and time from the filename format: rec-DD-MM-YYYY_HH-MM-timestamp.extension
        const parts = nameWithoutExt.split('_')

        if (parts.length >= 2) {
            const datePart = parts[0].replace('rec-', '')
            const timeParts = parts[1].split('-')

            // Try to format as a readable date and time
            try {
                const dateSections = datePart.split('-')
                if (dateSections.length === 3) {
                    const day = dateSections[0]
                    const month = dateSections[1]
                    const year = dateSections[2]

                    const hours = timeParts[0] || '00'
                    const minutes = timeParts[1] || '00'

                    return `Recording from ${day}/${month}/${year} at ${hours}:${minutes}`
                }
            } catch (err) {
                // If parsing fails, return a simplified version
                return `Recording - ${fileName}`
            }
        }

        // If we can't parse it properly, just return the filename
        return `Recording - ${fileName}`
    }

    // Handle audio element events
    const handleAudioLoaded = () => {
        setLoading(false)
    }

    const handleAudioError = () => {
        setLoading(false)
        setError(true)
    }

    // Reset state and clean up audio when modal opens/closes
    useEffect(() => {
        if (open) {
            setLoading(true)
            setError(false)
        } else {
            // When modal closes, pause audio and reset
            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
            }
        }
    }, [open])

    // Clean up resources when component unmounts
    useEffect(() => {
        return () => {
            // Ensure we clean up the audio element when component unmounts
            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current.src = ""
                audioRef.current.load()
            }
        }
    }, [])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">
                        {formatRecordingName(recording.fileName)}
                    </Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ padding: '20px 0' }}>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        File: {recording.fileName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Added: {moment(recording.createdAt).format('MMMM DD, YYYY [at] h:mm A')}
                    </Typography>

                    {/* Audio Player Section */}
                    <div style={{ marginTop: 20, textAlign: 'center', minHeight: 80, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {loading && (
                            <CircularProgress size={30} style={{ marginBottom: 10 }} />
                        )}

                        {error && (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#f44336' }}>
                                <ErrorOutlineIcon style={{ fontSize: 40, marginBottom: 10 }} />
                                <Typography variant="body2">
                                    Unable to load audio file. The file may not exist or isn't in a supported format.
                                </Typography>
                            </div>
                        )}

                        <audio
                            ref={audioRef}
                            src={recording.fileUrl}
                            controls
                            preload="auto"
                            style={{
                                width: '100%',
                                maxWidth: '500px',
                                display: loading ? 'none' : (error ? 'none' : 'block')
                            }}
                            onCanPlay={handleAudioLoaded}
                            onError={handleAudioError}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RecordingPlayer 