import React, { useEffect } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import GeneralDropdownField from '../general/DropdownField'
import ConferenceRoom from './ConferenceRoom'

const PushToTalkMenu = (props) => {
    const {
        user, department, pushToTalkDepartments, loaders, pluginHandle, roomId, webrtcUp, remoteStream, participants, roomJoined, audioEnabled, getPushToTalkDepartments,
        setPttJanusRef, setPttDepartment, setPttPluginHandle, setPttRoomId, setPttWebrtcUp, setPttRemoteStream, setPttParticipants, setPttRoomJoined,
        handlePttTouchUp, handlePttTouchDown, setPttFloatingModalVisible, destroyJanusInstance
    } = props

    useEffect(() => {
        getPushToTalkDepartments()
        setPttFloatingModalVisible(true)

        return () => {
            setPttFloatingModalVisible(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loaders.getPushToTalkDepartments) return (
        <Grid
            item
            xs={12}
            style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
        >
            <CircularProgress
                size={36}
            />
        </Grid>
    )

    if (!pushToTalkDepartments?.length) return (
        <Grid
            container
        >
            <Grid
                item
                xs={12}
                style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
            >
                <Typography
                    variant='body1'
                >
                    No pushToTalkDepartments were found.
                </Typography>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            style={{ margin: 16 }}
        >
            {!department?.name ?
                <Grid
                    container
                    style={{ marginBottom: 8 }}
                >
                    <Typography variant="h6" >
                        Select Department *
                    </Typography>
                    <GeneralDropdownField
                        width={12}
                        options={
                            pushToTalkDepartments?.sort((a, b) => a.name.localeCompare(b.name))?.map(department => ({
                                value: department._id,
                                text: `${department.name} ${department.agency ?? ''}`
                            })
                            )}
                        format="autocomplete"
                        placeholder='Select a department...'
                        statePath={`selectedDepartment`}
                        value={department?._id}
                        handleInputChange={(_, { value }) => setPttDepartment(pushToTalkDepartments.find(dept => dept._id === value))}
                    />
                </Grid>
                :
                null
            }
            {department?._id ?
                <ConferenceRoom
                    key={department._id}
                    user={user}
                    department={department}
                    pluginHandle={pluginHandle}
                    roomId={roomId}
                    webrtcUp={webrtcUp}
                    remoteStream={remoteStream}
                    participants={participants}
                    roomJoined={roomJoined}
                    audioEnabled={audioEnabled}
                    setPttJanusRef={setPttJanusRef}
                    setPttPluginHandle={setPttPluginHandle}
                    setPttRoomId={setPttRoomId}
                    setPttWebrtcUp={setPttWebrtcUp}
                    setPttRemoteStream={setPttRemoteStream}
                    setPttParticipants={setPttParticipants}
                    setPttRoomJoined={setPttRoomJoined}
                    handlePttTouchUp={handlePttTouchUp}
                    handlePttTouchDown={handlePttTouchDown}
                    destroyJanusInstance={destroyJanusInstance}
                />
                :
                null
            }

        </Grid>
    )
}

export default PushToTalkMenu;
