import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, CircularProgress, Typography } from '@material-ui/core'
import MaterialIcon from 'material-icons-react'
import axios from 'axios'
import moment from 'moment-timezone'
import theme from '../../utilities/theme'
import LogoIcon from '../../assets/logo.png'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import MessageIcon from '@material-ui/icons/Message'
import DispatchStaticMap from './maps/DispatchStaticMap'
import { IsMobile } from '../../utilities/Helpers'
import { androidUrl, iOSUrl } from './AppstoreButtons'

const LongDispatchMessagePublicView = (props) => {
    const { showSideBar } = props
    // eslint-disable-next-line no-unused-vars
    const [dispatchId, setDispatchId] = useState(props.match.params.dispatchId)
    const [dispatch, setDispatch] = useState(null)
    const [dispatchLoader, setDispatchLoader] = useState(false)

    useEffect(() => {
        getDispatch()
    }, [])

    const getDispatch = async () => {
        if (!dispatchLoader) {
            setDispatchLoader(true)
            try {
                const getDispatchRespone = await axios.get(`/api/dispatches/longDispatchMessage?dispatchId=${dispatchId}`)
                if (getDispatchRespone.data.success) {
                    setDispatchLoader(false)
                    let dispatchObject = { ...getDispatchRespone.data.dispatch }
                    setDispatch(dispatchObject)
                    return dispatchObject
                }
            } catch (e) { }
            setDispatchLoader(false)
        }
    }

    if (dispatchLoader) return (
        <Grid
            container
            style={{ background: theme.palette.primary.dark, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: '100vh', marginLeft: showSideBar ? -125 : 0 }}
        >
            <CircularProgress
                size={36}
                style={{ color: theme.palette.primary.light }}
            />
        </Grid>
    )

    return (
        <Grid
            container
            item
            style={{
                background: theme.palette.primary.dark,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',  // Center all content
                justifyContent: 'flex-start',  // Start from top
                minHeight: '100vh',
                marginLeft: (!IsMobile() && showSideBar) ? -125 : 0,
                padding: '0 clamp(4px, 2%, 16px)',  // Reduced padding for mobile
                overflowX: 'hidden',
                maxWidth: '100vw',
                width: '100%'  // Ensure full width
            }}
        >
            <Grid
                item
                style={{
                    marginTop: 'clamp(16px, 3vh, 24px)',    // Reduced margins
                    marginBottom: 'clamp(16px, 3vh, 24px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    style={{
                        height: 'clamp(40px, 8vw, 60px)',  // Responsive image size
                        marginRight: 8,
                        backgroundColor: theme.palette.primary.background,
                        color: theme.palette.primary.dark
                    }}
                    src={LogoIcon}
                    alt="FireTEXT"
                />
                <h1
                    style={{
                        fontSize: 'clamp(28px, 6vw, 40px)',  // Responsive font size
                        color: theme.palette.primary.light
                    }}
                >
                    FireTEXT<sup style={{ fontSize: 'clamp(14px, 3vw, 18px)', position: 'relative', top: -12 }}>®</sup>
                </h1>
            </Grid>
            {!dispatch ?
                <Typography
                    variant='body1'
                    style={{
                        padding: 8,  // Reduced padding
                        color: theme.palette.primary.light,
                        fontSize: 'clamp(14px, 3vw, 16px)'  // Responsive font
                    }}
                    align='center'
                >
                    The dispatch you are looking for is not found.
                </Typography>
                :
                <>
                    {dispatch.active === true ?
                        <Grid
                            item
                            style={{
                                marginTop: 'clamp(8px, 2vh, 16px)',  // Reduced margins for mobile
                                marginBottom: 'clamp(8px, 2vh, 16px)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                maxWidth: '800px',  // Limit content width
                                gap: '8px'
                            }}
                        >
                            {dispatch?.department?.name &&
                                <Typography
                                    variant='body1'
                                    style={{
                                        color: theme.palette.primary.light,
                                        fontSize: 'clamp(14px, 3vw, 16px)',
                                        marginBottom: 4  // Reduced margin
                                    }}
                                >
                                    Department {dispatch.department.name}
                                </Typography>
                            }
                            {dispatch.createdAt &&
                                <Typography
                                    variant='body2'
                                    style={{
                                        color: theme.palette.primary.light,
                                        margin: '4px 8px',  // Reduced margins
                                        fontSize: 'clamp(12px, 2.5vw, 14px)'
                                    }}
                                >
                                    <b>Created</b>{` ${moment.tz(dispatch.createdAt, moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}`}
                                </Typography>
                            }
                            {dispatch?.location?.addressString && (
                                <Grid
                                    container  // Changed to container
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '4px',  // Reduced padding
                                        width: '100%'
                                    }}
                                >
                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            maxWidth: '100%'  // Ensure text wraps on mobile
                                        }}
                                    >
                                        <MyLocationIcon style={{
                                            color: theme.palette.primary.light,
                                            fontSize: 'clamp(18px, 4vw, 24px)',
                                            flexShrink: 0  // Prevent icon from shrinking
                                        }} />
                                        <Typography
                                            style={{
                                                color: theme.palette.primary.light,
                                                marginLeft: 8,
                                                fontWeight: 'bold',
                                                fontSize: 'clamp(14px, 3vw, 16px)',
                                                wordBreak: 'break-word'
                                            }}
                                        >
                                            {dispatch.location.addressString}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {dispatch.message &&
                                <Grid
                                    container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '4px',  // Reduced padding
                                        width: '100%'
                                    }}
                                >
                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <MessageIcon style={{
                                            color: theme.palette.primary.light,
                                            fontSize: 'clamp(18px, 4vw, 24px)',
                                            flexShrink: 0
                                        }} />
                                        <Typography
                                            style={{
                                                color: theme.palette.primary.light,
                                                marginLeft: 8,
                                                wordBreak: 'break-word',
                                                fontSize: 'clamp(14px, 3vw, 16px)',
                                                lineHeight: 1.4
                                            }}
                                        >
                                            {dispatch.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {dispatch?.callTypeObject &&
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: 8,  // Reduced margin
                                        flexDirection: 'row',
                                        alignItems: 'baseline',
                                        justifyContent: 'center',
                                        color: theme.palette.primary.main,
                                        width: '100%',
                                        padding: '8px 0'  // Reduced padding
                                    }}
                                >
                                    <span
                                        style={dispatch?.callTypeObject?.icon2 ? { marginRight: 16 } : { marginRight: 8 }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <MaterialIcon icon={dispatch?.callTypeObject?.icon1} size="tiny" color="#ff9800" style={{ color: theme.palette.primary.light, }} />
                                            {dispatch?.callTypeObject?.icon2 && (
                                                <span style={{ position: 'absolute', marginRight: -30, marginTop: -10 }}>
                                                    <MaterialIcon icon={dispatch?.callTypeObject?.icon2} size="tiny" color="#ff9800" style={{ color: theme.palette.primary.light, }} />
                                                </span>
                                            )}
                                        </div>
                                    </span>
                                    <Typography style={{ color: theme.palette.primary.light, marginLeft: 4, marginTop: -4 }} variant="h6">
                                        {dispatch.callTypeObject.callType}
                                    </Typography>
                                </div>
                            }
                            {dispatch?.location &&
                                <Grid
                                    container
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '4px'
                                    }}
                                >
                                    <Grid
                                        item
                                        style={{
                                            width: '100%',
                                            maxWidth: '300px'
                                        }}
                                    >
                                        <DispatchStaticMap
                                            userId={null}
                                            dispatchLocation={dispatch?.location}
                                            responders={[]}
                                            style={{
                                                width: '100%',
                                                height: 'auto',  // Slightly reduced height
                                                borderRadius: '4px'  // Optional: adds slight rounding to map
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        :
                        <Typography
                            variant='body1'
                            style={{
                                padding: 8,  // Reduced padding
                                color: theme.palette.primary.light,
                                fontSize: 'clamp(14px, 3vw, 16px)'
                            }}
                            align='center'
                        >
                            The dispatch you are looking for is no longer active.
                        </Typography>
                    }
                </>
            }

            <Grid
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    gutterBottom
                    align='center'
                    style={{ padding: 64, paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.light, }}
                >
                    Download the FireTEXT app now for real-time emergency alerts and enhanced features! Available for both iOS and Android devices.
                </Typography>
                <div
                    style={{
                        height: '88px',
                        width: '225px',
                        display: 'inline-block',
                        marginBottom: 8
                    }}
                >
                    <a
                        href={iOSUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="iOS Store Button"
                    >
                        <img
                            alt='Get it on App Store'
                            src='https://s3.amazonaws.com/emgcy.io/appstore-button.png'
                            style={{
                                height: '88px',
                                width: '225px',
                            }}
                        />
                    </a>
                </div>
                <div
                    style={{
                        height: '85px',
                        width: '225px',
                        display: 'inline-block'
                    }}
                >
                    <a
                        href={androidUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Android Store Button"
                    >
                        <img
                            alt='Get it on Google Play'
                            src='https://s3.amazonaws.com/emgcy.io/googleplay-button.png'
                            style={{
                                height: '85px',
                                width: '225px',
                            }}
                        />
                    </a>
                </div>
            </Grid>
        </Grid>
    )
}

export default withRouter(LongDispatchMessagePublicView)