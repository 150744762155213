import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, ButtonBase } from '@material-ui/core'
import MicIcon from '@material-ui/icons/Mic'
import PttInterface from './PttInterface'

const PttFloatingModal = ({
    departmentName,
    audioEnabled,
    participants,
    handlePttTouchUp,
    handlePttTouchDown,
    destroyJanusInstance
}) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            {open === false ?
                <ButtonBase
                    onClick={() => setOpen(true)}
                    style={{
                        height: 80,
                        width: 80,
                        borderRadius: 40,
                        backgroundColor: 'red',
                        position: 'fixed',
                        bottom: 80,
                        right: 80,
                        zIndex: 10000
                    }}
                >
                    <MicIcon style={{ fontSize: 40, margin: 'auto' }} />
                </ButtonBase>
                :
                null
            }
            <Dialog
                aria-labelledby='customized-dialog-title'
                onClose={() => setOpen(false)}
                open={open}
                PaperProps={{ style: { minWidth: '80vw', minHeight: '90vh' } }}
            >
                <DialogTitle
                    style={{
                        marginTop: 32,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline'
                    }}
                    disableTypography
                >
                    <Typography
                        variant='h6'
                    >
                        Push To Talk
                    </Typography>
                </DialogTitle>
                <DialogContent
                    dividers
                >
                    <PttInterface
                        departmentName={departmentName}
                        audioEnabled={audioEnabled}
                        participants={participants}
                        handlePttTouchUp={handlePttTouchUp}
                        handlePttTouchDown={handlePttTouchDown}
                        destroyJanusInstance={destroyJanusInstance}
                    />
                </DialogContent>

                <DialogActions
                    style={{ display: 'flex', flex: 1, maxHeight: 50 }}
                >


                </DialogActions>
            </Dialog>
        </>
    )
}

export default PttFloatingModal;
