// React
import React from 'react'
// MUI
import { Button, Grid, Typography } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
// Utils
import theme from '../../utilities/theme'
import LogoIcon from '../../assets/logo.png'

export const androidUrl = 'https://play.google.com/store/apps/details?id=com.firetext.firetextapp'
export const iOSUrl = 'https://apps.apple.com/us/app/firetextapp/id1555116567'

const AppstoreButtons = ({ signOut }) => {
    return (
        <Grid
            container
            style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%', padding: 0, margin: 0, marginBottom: 'auto' }}
        >
            <Grid
                item
                style={{
                    marginTop: 48,
                    marginBottom: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    style={{
                        height: 60,
                        marginRight: 8,
                        backgroundColor: theme.palette.primary.background,
                        color: theme.palette.primary.dark
                    }}
                    src={LogoIcon}
                    alt="FireTEXT"
                />
                <h1
                    style={{ fontSize: 40 }}
                >
                    FireTEXT<sup style={{ fontSize: 18, position: 'relative', top: -10 }}>®</sup>
                </h1>

            </Grid>

            <Typography
                variant="body2"
                gutterBottom
                align='center'
                style={{ padding: 64, paddingTop: 0, paddingBottom: 0 }}
            >
                To use FireTEXT on your phone, please install the app from the app store.
            </Typography>
            <Typography
                variant="body2"
                gutterBottom
                align='center'
                style={{ padding: 64, paddingTop: 0, paddingBottom: 40 }}
            >
                To reset your password or listen to public dispatches, kindly sign out on the browser first.
            </Typography>
            <Grid
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        height: '88px',
                        width: '225px',
                        display: 'inline-block',
                        marginBottom: 8
                    }}
                >
                    <a
                        href={iOSUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="iOS Store Button"
                    >
                        <img
                            alt='Get it on App Store'
                            src='https://s3.amazonaws.com/emgcy.io/appstore-button.png'
                            style={{
                                height: '88px',
                                width: '225px',
                            }}
                        />
                    </a>
                </div>
                <div
                    style={{
                        height: '85px',
                        width: '225px',
                        display: 'inline-block'
                    }}
                >
                    <a
                        href={androidUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Android Store Button"
                    >
                        <img
                            alt='Get it on Google Play'
                            src='https://s3.amazonaws.com/emgcy.io/googleplay-button.png'
                            style={{
                                height: '85px',
                                width: '225px',
                            }}
                        />
                    </a>
                </div>
            </Grid>


            {signOut &&
                <Button
                    startIcon={<ExitToAppIcon />}
                    onClick={() => signOut?.()}
                    variant='outlined'
                    style={{
                        margin: 'auto',
                        marginTop: 32
                    }}
                >
                    Signout
                </Button>
            }


        </Grid>
    )
}

export default AppstoreButtons;