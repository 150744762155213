// React
import React, { useState, useEffect, useRef } from 'react'

// Material UI
import { Grid, Divider, Typography, CircularProgress, Tooltip } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import MaterialIcon from 'material-icons-react'
import RadioIcon from '@material-ui/icons/Radio'
import PersonIcon from '@material-ui/icons/Person'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ForumIcon from '@material-ui/icons/Forum';

// Components
import ViewDispatchModal from '../viewDispatch/index'
import DispatchAudioPlayer from '../general/audio-player/NewDispatchAudioPlayer'
import DispatchStaticMap from '../general/maps/DispatchStaticMap'

// Utilities
import theme from '../../utilities/theme'
import axios from 'axios'
import { populateResponders } from '../general/maps/utils'
import DispatchCreatedAt from '../general/DispatchCreatedAt'


const CallHistory = (props) => {
    const { accessToken, user, socket } = props
    const [dispatches, setDispatches] = useState([])
    const [openDispatch, handleOpenDispatch] = useState(null)
    const [dispatchesLoader, setDispatchesLoader] = useState(false)

    const gridRef = useRef(null)

    const scrollToTop = () => {
        if (gridRef.current) {
            gridRef.current.scrollTo(0, 0);
        }
    }

    const getDispatches = async () => {
        scrollToTop()
        if (!dispatchesLoader) {
            setDispatchesLoader(true)

            const config = {
                headers: { Authorization: accessToken },
                params: { queryType: 'overview' }
            }
            try {
                const getDispatchesResponse = await axios.get('/api/dispatches', config)
                if (getDispatchesResponse.data.success) {
                    let dispatches = [...getDispatchesResponse.data.dispatches]
                    setDispatches(dispatches)
                }
            } catch (error) { }
            setDispatchesLoader(false)
        }
    }

    const onResponderUpdated = ({ index, coordinates, responderStatus, dispatchId }) => {
        try {
            const dispatchIndex = dispatches.findIndex(d => d._id === dispatchId)
            if (dispatchIndex >= 0 && dispatchIndex < dispatches.length) {
                const updatedDispatch = dispatches[dispatchIndex]

                const responders = updatedDispatch.responders

                if (index >= 0 && index < responders.length && responders[index] !== undefined) {
                    const updatedResponders = [...responders]
                    updatedResponders[index] = {
                        ...updatedResponders[index]
                    }

                    if (coordinates) {
                        updatedResponders[index].coordinates = coordinates
                    }
                    if (responderStatus) {
                        updatedResponders[index].responderStatus = responderStatus
                    }

                    updatedDispatch.responders = updatedResponders

                    const updatedDispatches = [
                        ...dispatches
                    ]
                    updatedDispatches[dispatchIndex] = updatedDispatch

                    setDispatches(updatedDispatches)

                }

            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getDispatches()
        socket.on('change-dispatches', getDispatches)

        return () => {
            socket.off('change-dispatches', getDispatches)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])

    useEffect(() => {
        getDispatches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        for (const dispatch of dispatches) {
            socket.on(`realtime-location-${dispatch._id}`, onResponderUpdated)
        }

        return () => {
            for (const dispatch of dispatches) {
                socket.off(`realtime-location-${dispatch._id}`, onResponderUpdated)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatches, socket])

    return (
        <Grid
            ref={gridRef}
            item
            style={{ backgroundColor: theme.palette.primary.backgroundGrey, paddingRight: 8, height: '95vH', overflowY: 'scroll', overflowX: 'hidden' }}
        >
            {dispatchesLoader ?
                <Grid
                    item
                    xs={12}
                    style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
                >
                    <CircularProgress
                        size={36}
                    />
                </Grid>
                :
                <Grid>
                    {(!dispatches || !dispatches.length) ?
                        <Typography
                            variant='body1'
                            style={{ display: 'flex', flex: 1, padding: 24, alignItems: 'center', justifyContent: 'center' }}
                        >
                            No recent dispatches.
                        </Typography>
                        :
                        <Typography
                            variant='body1'
                            style={{ display: 'flex', flex: 1, padding: 24, alignItems: 'center', justifyContent: 'center' }}
                        >
                            Recent Dispatches
                        </Typography>
                    }
                </Grid>
            }
            {openDispatch &&
                <ViewDispatchModal
                    accessToken={accessToken}
                    userId={user._id}
                    dispatch={openDispatch}
                    open={openDispatch !== null}
                    closeModal={() => handleOpenDispatch(null)}
                    socket={socket}
                    allowEdit={!openDispatch.deleted && openDispatch.active === true && (user?.type === 'superAdmin' || user?.type === 'dispatcher' || (user?.type === 'user' && user?.departments?.find(dept => dept.departmentId === openDispatch.departmentId)?.role === 'departmentAdmin'))}
                    allowDelete={!openDispatch.deleted && openDispatch.active === true && (user?.type === 'superAdmin' || (user?.type === 'user' && user?.departments?.find(dept => dept.departmentId === openDispatch.departmentId)?.role === 'departmentAdmin'))}
                    showCreatedBy={user?.type === 'superAdmin' || (user?.type === 'user' && user?.departments?.find(dept => dept.departmentId === openDispatch.departmentId)?.role === 'departmentAdmin')}
                    showIncidentId={typeof openDispatch?.incidentId === 'string' && (user?.type === 'superAdmin' || user?.type === 'dispatcher' || (user?.type === 'user' && user?.departments?.find(dept => dept.departmentId === openDispatch.departmentId)?.role === 'departmentAdmin'))}
                />
            }
            {dispatches && dispatches.map((call, index) => (
                <Grid
                    key={call._id}
                    xs={12}
                    item
                    style={call?.deleted === true ? { marginTop: 16, cursor: 'pointer', borderLeft: '2px solid #f04141', borderRadius: 4, opacity: 0.5 } : { marginTop: 16, cursor: 'pointer' }}
                    onClick={() => handleOpenDispatch(call)}
                >
                    <Grid
                        xs={12}
                        item
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        style={{ paddingRight: 16, marginBottom: 16 }}
                    >
                        <span
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: theme.palette.primary.main }}
                        >
                            {call.callTypeObject ?
                                <span
                                    style={call.callTypeObject.icon2 ? { marginRight: 16 } : { marginRight: 8 }}
                                >
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                        {call.callTypeObject.icon2 &&
                                            <span
                                                style={{ position: 'relative', marginRight: -20, marginBottom: -10 }}
                                            >
                                                <MaterialIcon icon={call.callTypeObject.icon2} size='tiny' color='#ff9800' />
                                            </span>
                                        }
                                        <MaterialIcon icon={call.callTypeObject.icon1} size='tiny' color={theme.palette.primary.main} />
                                    </div>
                                </span>
                                : (call.isVoice || call.voiceUrl) ?
                                    <span
                                        style={{ marginRight: 8 }}
                                    >
                                        <div
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                        >
                                            <RadioIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
                                        </div>
                                    </span>
                                    :
                                    <span
                                        style={{ marginRight: 8 }}
                                    >
                                        <div
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                        >
                                            <MessageIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
                                        </div>
                                    </span>
                            }
                            <Typography
                                variant='subtitle2'
                            >
                                {call.callTypeObject ? call.callTypeObject.callType : (call.voiceUrl || call.isVoice) ? 'Automated Dispatch' : 'Other'}
                            </Typography>
                        </span>
                        <DispatchCreatedAt
                            createdAt={call.createdAt}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ marginBottom: 16, paddingRight: 16 }}
                    >
                        <Typography
                            variant='subtitle2'
                        >
                            {call.department ? call.department.name : 'Deleted Department'}
                        </Typography>
                    </Grid>

                    <DispatchStaticMap
                        userId={user._id}
                        dispatchLocation={call?.location}
                        responders={populateResponders(call)}
                    />

                    {(call.voiceUrl || call.isVoice) &&
                        <DispatchAudioPlayer
                            dispatch={call}
                        />
                    }
                    {
                        call.transcriptionText ?
                            <Grid>
                                <Typography
                                    variant='body2'
                                    style={{ margin: '0 10px' }}
                                >
                                    <b>Transcription</b>{`: ${call.transcriptionText}`}
                                </Typography>
                            </Grid>
                            :
                            null
                    }
                    <RespondersAndAttachmentsCountComponent
                        responders={call.responders}
                        attachments={call.attachments}
                        comments={call.comments}
                    />
                    <Grid
                        item
                        xs={12}
                        style={{ paddingRight: 16, marginBottom: 16 }}
                    >
                        <Typography
                            variant='body1'
                            style={{ wordWrap: 'break-word' }}
                        >
                            {call.message}
                        </Typography>
                    </Grid>
                    {index < dispatches.length - 1 &&
                        <Divider />
                    }
                </Grid>
            ))}
        </Grid>
    )
}

const RespondersAndAttachmentsCount = {
    responders: <PersonIcon style={{ fontSize: 22, marginRight: 16, color: theme.palette.secondary.main }} />,
    comments: <ForumIcon style={{ fontSize: 22, marginRight: 16, color: theme.palette.secondary.main }} />,
    locations: <MyLocationIcon style={{ fontSize: 22, marginRight: 16, color: theme.palette.secondary.main }} />,
    attachments: <AttachmentIcon style={{ fontSize: 22, marginRight: 16, color: theme.palette.secondary.main }} />,
}

const formatCount = (key, count) => count === 0 ? `No ${key}` : `${count} ${count === 1 ? key.slice(0, -1) : key}`;

const RenderResponderAndAttachmentsCount = (key, responderStatus, count) => {
    return (
        <Grid
            key={key}
            item
            xs={2.5}
        >
            <Tooltip
                title={formatCount(responderStatus, count)}
                placement="top-start"
            >
                <Grid
                    container
                >
                    <Typography
                        variant='body2'
                        style={{ marginRight: 2, fontSize: 16 }}
                    >
                        {count}
                    </Typography>
                    {RespondersAndAttachmentsCount[responderStatus]}
                </Grid>
            </Tooltip>
        </Grid>
    )
}

// this component renders icons and counts that represents responders, locations and attachments
const RespondersAndAttachmentsCountComponent = ({ responders, attachments, comments, style }) => {
    const respondersActivityCount = {
        'responders': responders?.length ?? 0,
        'comments': comments?.length ?? 0,
        'locations': responders?.filter(resp => typeof resp.coordinates?.latitude === 'number').length ?? 0,
        'attachments': attachments?.length ?? 0,
    }
    const activityKeys = Object.keys(respondersActivityCount)
    return (
        <Grid
            item
            xs={12}
            style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', margin: '8px 0px', ...style }}
        >
            {activityKeys.map((activity, index) => (RenderResponderAndAttachmentsCount(index, activity, respondersActivityCount[activity])))}
        </Grid>
    )
}

export default CallHistory;
