// React
import React from 'react'

// Material UI
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import Fade from '@material-ui/core/Fade'

// React Router
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

// Components
import Auth from './components/login/index'
import ActivateAccount from './components/activate-account/index'
import DepartmentInvites from './components/navigation/bellNotification/DepartmentInvitesScreen'
import ResetPassword from './components/reset-password/index'
import Navigation from './components/navigation/index'
import Dashboard from './components/dashboard/index'
import Chat from './components/chat/index'
import History from './components/history/index'
import Settings from './components/settings/index'
import PublicAudioDispatchPlayer from './components/general/publicAudioDispatchPlayer'
import Maps from './components/maps'
import FiretextAnnouncementScreen from './components/navigation/bellNotification/FiretextAnnouncementScreen'
import AwwSignupForm from './components/aww'

// Utilities
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import io from 'socket.io-client'
import Decode from 'jwt-decode'
import GetTheme from './utilities/GetTheme'
import { IsMobile } from './utilities/Helpers'
import './App.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import axios from 'axios'
import { throttle } from 'underscore'
import InfoChangeRequestConfirmation from './components/InfoChangeRequestConfirmation'
import CadDepartments from './components/CAD'
import FiretextAnnouncement from './components/settings/firetextAnnouncement'
import PushToTalkMenu from './components/ptt'
import RemoteStreamPlayer from './components/ptt/RemoteStreamPlayer'
import PttFloatingModal from './components/ptt/PttFloatingModa'
import ProtectedRoute from './utilities/ProtectedRoutes'
import LongDisptchMessagePublicView from './components/general/LongDisptchMessagePublicView'
import UserRegistrationCode from './components/userRegistrationCode'
import Recordings from './components/recordings'

const firebaseConfig = {
    apiKey: "AIzaSyCP4dQDas8xXO4RzxpK7fn--b8bBBDsW6M",
    authDomain: "firetext-772d7.firebaseapp.com",
    projectId: "firetext-772d7",
    storageBucket: "firetext-772d7.appspot.com",
    messagingSenderId: "229112199068",
    appId: "1:229112199068:web:1eb707a8f35d08bb8f7a59",
    measurementId: "G-RQ8ZMMKQ87"
}

const firebaseApp = initializeApp(firebaseConfig)
// eslint-disable-next-line no-unused-vars
const firebaseAnalytics = getAnalytics(firebaseApp)

export const RadioUrl = 'https://d1b99nnzz4c724.cloudfront.net'

class App extends React.Component {
    state = {
        currentTheme: 'light',
        theme: GetTheme('light'),
        user: null,
        accessToken: null,
        refreshToken: null,
        email: null,
        id: null,
        redirect: null,
        loaded: false,
        menuOpen: true,
        socket: null,
        showSideBar: false,
        radioChannel: {
            _id: null,
            description: '',
            playing: false
        },
        isMobile: false,
        pushToTalk: {
            janusRef: null,
            department: null,
            pluginHandle: null,
            roomId: null,
            webrtcUp: false,
            remoteStream: false,
            participants: [],
            roomJoined: false,
            audioEnabled: false,
            hidePttFloatingModal: false,
            pushToTalkDepartments: [],
            loaders: {
                getPushToTalkDepartments: false
            }
        }
    }

    componentDidMount = async () => {
        this.checkIfMobileDevice()
        this.getToken()
        this.setAxiosResponseInterceptor()
        //disable console logs in production
        if (process.env.NODE_ENV !== 'development') {
            console.log = () => null
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.accessToken !== this.state.accessToken && this.state.accessToken) {
            if (this.state.socket) {
                this.state.socket?.off()
                this.state.socket?.disconnect()
                this.state.socket?.close()
            }

            this.getPushToTalkDepartments()

            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                auth: {
                    token: this.state.accessToken
                }
            }).connect()

            try {
                socket.on('connect', () => { })

                socket.on('update-user', (user) => {
                    if (typeof user === 'object' && user !== null) {
                        this.setState({
                            user,
                            currentTheme: (user?.theme || 'light'),
                            theme: GetTheme(user?.theme || 'light'),
                            loaded: true,
                            showSideBar: true
                        })
                    }
                    else {
                        this.setState({ accessToken: null, refreshToken: null, email: null, id: null, user: null, loaded: true })
                    }
                })

                socket.on('connect_error', (error) => {
                    if (error?.message === 'Authentication error') {
                        this.loginAgainWithRefreshToken({ reload: true })
                    }
                })

                socket.on("disconnect", (reason) => {
                    if (reason === "io server disconnect") {
                        // the disconnection was initiated by the server, you need to reconnect manually
                        socket.connect()
                    }
                })
            } catch (error) {
            }

            this.setState({ socket })
        }
    }

    componentWillUnmount = () => {
        this.state.socket?.off()
        this.state.socket?.close()
    }

    checkIfMobileDevice = () => {
        const isMobile = IsMobile()
        this.setState({ isMobile })
    }

    setAxiosResponseInterceptor = async () => {
        // if any http request fails due to token expiry we will 
        // refresh token and rerequest
        axios.interceptors.response.use((response) => {
            return response
        }, async (error) => {
            if (error?.response?.status === 401) {
                await this.loginAgainWithRefreshToken({ reload: false })
                const accessToken = localStorage.getItem('accessToken')
                if (accessToken) {
                    const originalRequestConfig = error.config;
                    originalRequestConfig.headers['Authorization'] = accessToken
                    originalRequestConfig.headers['numRetries'] = (originalRequestConfig.headers['numRetries'] ?? 0) + 1

                    if (originalRequestConfig.headers['numRetries'] <= 1) {
                        /** retry only one more time with new access token if response is 401 **/
                        return axios.request(originalRequestConfig)
                    }

                }
            }
            return Promise.reject(error)
        })
    }

    toggleMenu = () => {
        this.setState(prevState => ({ menuOpen: !prevState.menuOpen }))
    }

    setTheme = (currentTheme) => {
        this.setState({ currentTheme, theme: GetTheme(currentTheme) })
    }

    getAuthenticationStatus = () => {
        const { user } = this.state
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            try {
                const decoded = Decode(accessToken)
                var expirationDate = new Date(decoded.exp * 1000)
                if (expirationDate > new Date()) {
                    return user
                } else {
                    this.loginAgainWithRefreshToken({ reload: false })
                    return user
                }
            }
            catch (err) {
                return false
            }
        } else {
            return false
        }
    }

    clearLocalStorage = () => {
        try {
            let storedSortedDepartments = localStorage.getItem('storedSortedDepartments')
            let storedFavouriteDepartments = localStorage.getItem('storedFavouriteDepartments')
            let usedFavOrDnDFlag = localStorage.getItem('usedFavOrDnD')


            localStorage.clear()

            if (storedSortedDepartments) {
                localStorage.setItem('storedSortedDepartments', storedSortedDepartments)
            }
            if (storedFavouriteDepartments) {
                localStorage.setItem('storedFavouriteDepartments', storedFavouriteDepartments)
            }
            if (usedFavOrDnDFlag) {
                localStorage.setItem('usedFavOrDnD', usedFavOrDnDFlag)
            }


        } catch (error) { }
    }

    getToken = () => {
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            try {
                const decoded = Decode(accessToken)
                var expirationDate = new Date(decoded.exp * 1000)
                if (expirationDate > new Date()) {
                    const email = localStorage.getItem('email')
                    const id = localStorage.getItem('id')
                    this.setState({
                        accessToken: accessToken,
                        email: email,
                        id: id
                    })
                } else {
                    this.loginAgainWithRefreshToken({ reload: true })
                }
            } catch (err) {
                this.setState({ refreshToken: null, accessToken: null, email: null, id: null, user: null, loaded: true })
            }
        } else {
            this.setState({ refreshToken: null, accessToken: null, email: null, id: null, user: null, loaded: true })
        }
    }

    setToken = (data, reload = true) => {
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)
        localStorage.setItem('email', data.email)
        localStorage.setItem('id', data.id)
        if (!reload) this.getToken()
        else window.location.reload()
    }

    loginAgainWithRefreshToken = throttle(async ({ reload }) => {
        try {
            const refreshToken = localStorage.getItem('refreshToken')
            if (refreshToken) {
                let refreshTokenResponse = await axios.post(`/api/auth/loginWithRefreshToken`, { refreshToken }, {})
                if (refreshTokenResponse?.data?.success) {
                    const data = refreshTokenResponse.data

                    this.setToken({
                        accessToken: data.token,
                        refreshToken: data.refreshToken,
                        email: data.email,
                        id: data.id
                    }, reload)
                }
            } else {
                this.clearLocalStorage()
                this.setState({ accessToken: null, refreshToken: null, email: null, id: null, user: null, loaded: true })
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                this.clearLocalStorage()
                this.setState({ accessToken: null, refreshToken: null, email: null, id: null, user: null, loaded: false })
            }
        }
    }, 5000, { trailing: false })

    signOut = () => {
        this.clearLocalStorage()
        this.state.socket.disconnect()
        this.setState({ accessToken: null, refreshToken: null, email: null, id: null, user: null, showSideBar: false })
        window.location.reload()
    }

    playRadio = (radioChannel) => {
        if (this.state.radioChannel && this.state.radioChannel.playing) {
            alert('Please stop the current radio before playing.')
            return
        }
        this.setState({
            radioChannel: {
                ...radioChannel,
                playing: true
            }
        })
    }

    pauseRadio = (radioChannel) => {
        this.setState({
            radioChannel: {
                ...radioChannel,
                playing: false
            }
        })
    }

    stopRadio = () => {
        this.setState({
            radioChannel: {}
        })
    }


    setPushToTalkDepartmentsLoading = (loading) => {
        this.setState(prevState => ({
            ...prevState,
            pushToTalk: {
                ...prevState.pushToTalk,
                loaders: {
                    ...prevState.pushToTalk.loaders,
                    getPushToTalkDepartments: loading
                }
            }
        }))
    }

    getPushToTalkDepartments = async () => {
        const { pushToTalk, accessToken } = this.state
        const { loaders } = pushToTalk

        if (!loaders.getPushToTalkDepartments) {
            this.setPushToTalkDepartmentsLoading(true)

            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments/ptt', config)
                if (getDepartmentsResponse.data.success) {
                    const pushToTalkDepartments = getDepartmentsResponse.data.departments

                    this.setState(prevState => ({
                        pushToTalk: {
                            ...prevState.pushToTalk,
                            pushToTalkDepartments,
                            loaders: {
                                ...prevState.pushToTalk.loaders,
                                getPushToTalkDepartments: false
                            }
                        }
                    }))
                }
                else {
                    this.setPushToTalkDepartmentsLoading(false)
                }
            } catch (error) {
                this.setPushToTalkDepartmentsLoading(false)
            }
        }
    }

    setPttJanusRef = (jR) => {
        const { janusRef } = this.state.pushToTalk

        if (janusRef && jR === janusRef) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                janusRef: jR
            }
        }))
    }

    destroyJanusInstance = () => {
        const { janusRef, pluginHandle, roomId } = this.state.pushToTalk
        if (pluginHandle) {
            const leave = { request: "leave", room: roomId }
            pluginHandle.send({ message: leave })
            pluginHandle.detach()
            console.log('pluginHandle detached')
        }

        if (janusRef) {
            console.log('destroy')
            janusRef.destroy()
        }

        this.setState(prevState => ({
            ...prevState,
            pushToTalk: {
                ...prevState.pushToTalk,
                janusRef: null,
                department: null,
                pluginHandle: null,
                roomId: null,
                webrtcUp: false,
                remoteStream: false,
                participants: [],
                roomJoined: false,
                audioEnabled: false
            }
        }))
    }

    setPttDepartment = (dept) => {
        const { department } = this.state.pushToTalk

        if (department && dept._id === department._id) return

        this.destroyJanusInstance()

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                department: dept
            }
        }))
    }

    setPttPluginHandle = (pH) => {
        const { pluginHandle } = this.state.pushToTalk

        if (pluginHandle && pH === pluginHandle) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                pluginHandle: pH
            }
        }))
    }

    setPttRoomId = (rId) => {
        const { roomId } = this.state.pushToTalk

        if (roomId && rId === roomId) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                roomId: rId
            }
        }))
    }

    setPttWebrtcUp = (wRtcUp) => {
        const { webrtcUp } = this.state.pushToTalk

        if (webrtcUp && wRtcUp === webrtcUp) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                webrtcUp: wRtcUp
            }
        }))
    }

    setPttRemoteStream = (rs) => {
        const { remoteStream } = this.state.pushToTalk

        if (remoteStream && rs === remoteStream) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                remoteStream: rs
            }
        }))
    }

    setPttParticipants = (prts) => {
        const { participants } = this.state.pushToTalk

        if (participants && prts === participants) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                participants: prts
            }
        }))
    }

    setPttRoomJoined = (rmJoined) => {
        const { roomJoined } = this.state.pushToTalk

        if (roomJoined && rmJoined === roomJoined) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                roomJoined: rmJoined
            }
        }))
    }

    setPttAudioEnabled = (audioEnbld) => {
        const { audioEnabled } = this.state.pushToTalk

        if (audioEnabled && audioEnbld === audioEnabled) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                audioEnabled: audioEnbld
            }
        }))
    }

    handlePttTouchDown = () => {
        console.log('touch down', { aaaa: this.state.pushToTalk?.pluginHandle })
        this.setPttAudioEnabled(true)
        this.state.pushToTalk?.pluginHandle?.send({
            message: { request: "self-unmute" },
            success: (response) => {
                console.log('self-unmute response:', response);
            },
            error: (error) => {
                console.error('self-unmute error:', error);
            }
        })
    }

    handlePttTouchUp = () => {
        console.log('touch up', { aaaa: this.state.pushToTalk?.pluginHandle })
        this.setPttAudioEnabled(false)
        this.state.pushToTalk?.pluginHandle?.send({
            message: { request: "self-mute" },
            success: (response) => {
                console.log('self-mute response:', response);
            },
            error: (error) => {
                console.error('self-mute error:', error);
            }
        })
    }

    setPttFloatingModalVisible = (hidePttFloatingModal) => { // when we go to ptt screen
        if (hidePttFloatingModal === this.state.pushToTalk.hidePttFloatingModal) return

        this.setState(prevState => ({
            pushToTalk: {
                ...prevState.pushToTalk,
                hidePttFloatingModal
            }
        }))
    }

    render() {
        const { accessToken, user, redirect, loaded, menuOpen, socket, currentTheme, theme, showSideBar, radioChannel, isMobile, pushToTalk } = this.state
        const { pushToTalkDepartments, loaders: pushToTalkDepartmentsLoaders } = pushToTalk

        const isDepartmentAdminInAnyDepartment = (user?.departments?.findIndex(department => department.role === 'departmentAdmin') !== -1)

        const showRecordingsTab = (user?.type === 'superAdmin' || isDepartmentAdminInAnyDepartment)

        if (!loaded) {
            return (
                <>
                </>
            )
        } else {
            return (
                <ThemeProvider
                    theme={theme}
                >
                    {/* <PTT /> */}
                    {pushToTalk.roomJoined && pushToTalk.remoteStream && (
                        <RemoteStreamPlayer
                            remoteStream={pushToTalk.remoteStream}
                        />
                    )}

                    {!pushToTalk.hidePttFloatingModal && pushToTalk.roomJoined && pushToTalk.remoteStream && (
                        <PttFloatingModal
                            departmentName={pushToTalk.department?.name}
                            audioEnabled={pushToTalk.audioEnabled}
                            participants={pushToTalk.participants}
                            handlePttTouchUp={this.handlePttTouchUp}
                            handlePttTouchDown={this.handlePttTouchDown}
                            destroyJanusInstance={this.destroyJanusInstance}
                        />
                    )}

                    <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={5000}
                        preventDuplicate
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        TransitionComponent={Fade}
                    >
                        <CssBaseline />

                        <Router>
                            {!isMobile &&
                                <Navigation
                                    user={user}
                                    accessToken={accessToken}
                                    toggleMenu={this.toggleMenu}
                                    menuOpen={menuOpen}
                                    signOut={this.signOut}
                                    currentTheme={currentTheme}
                                    radioChannel={radioChannel}
                                    playRadio={this.playRadio}
                                    pauseRadio={this.pauseRadio}
                                    stopRadio={this.stopRadio}
                                    socket={socket}
                                    pushToTalkDepartments={pushToTalkDepartments}
                                />
                            }
                            <div
                                style={{ marginLeft: (!isMobile && showSideBar) ? (menuOpen ? 250 : 57) : 0 }}
                            >
                                <Switch>
                                    {redirect &&
                                        <Redirect
                                            to={redirect}
                                        />
                                    }
                                    <Route
                                        exact
                                        path='/activate-account/:id/:tempHash'
                                        render={() => this.getAuthenticationStatus().activated ?
                                            <Redirect
                                                to='/dashboard'
                                            />
                                            :
                                            <ActivateAccount
                                                appState={this.state}
                                                accessToken={accessToken}
                                                getToken={this.getToken}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path='/aww-sign-up'
                                        render={() =>
                                            <AwwSignupForm
                                                showSideBar={!isMobile && showSideBar}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path='/public-audio-dispatch/:dispatchId'
                                        render={() => <PublicAudioDispatchPlayer
                                            showSideBar={!isMobile && showSideBar}
                                        />
                                        }
                                    />
                                    <Route
                                        exact
                                        path='/long-dispatch-message-public-view/:dispatchId'
                                        render={() => <LongDisptchMessagePublicView
                                            showSideBar={!isMobile && showSideBar}
                                        />
                                        }
                                    />
                                    <Route
                                        exact
                                        path='/reset-password/:id/:tempHash'
                                        render={() => this.getAuthenticationStatus().activated ?
                                            <Redirect
                                                to='/dashboard'
                                            />
                                            :
                                            <ResetPassword
                                                appState={this.state}
                                                accessToken={accessToken}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path='/info-change/:id'
                                        render={
                                            () => <InfoChangeRequestConfirmation
                                                showSideBar={!isMobile && showSideBar}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path='/user-registration/:departmentCode'
                                        render={
                                            () => <UserRegistrationCode
                                                showSideBar={false}
                                            />
                                        }
                                    />
                                    {(user?.type === 'superAdmin' || user?.type === 'dispatcher' || isDepartmentAdminInAnyDepartment) &&
                                        <ProtectedRoute
                                            exact
                                            path='/dashboard'
                                            componentType={Dashboard}
                                            getAuthenticationStatus={this.getAuthenticationStatus}
                                            accessToken={accessToken}
                                            user={user}
                                            socket={socket}
                                            signOut={this.signOut}
                                            isMobile={isMobile}
                                        />
                                    }
                                    <ProtectedRoute
                                        exact
                                        path='/pttDepartments'
                                        componentType={PushToTalkMenu}
                                        user={user}
                                        socket={socket}
                                        isMobile={isMobile}
                                        pushToTalkDepartments={pushToTalkDepartments}
                                        loaders={pushToTalkDepartmentsLoaders}
                                        department={pushToTalk.department}
                                        pluginHandle={pushToTalk.pluginHandle}
                                        roomId={pushToTalk.roomId}
                                        webrtcUp={pushToTalk.webrtcUp}
                                        remoteStream={pushToTalk.remoteStream}
                                        participants={pushToTalk.participants}
                                        roomJoined={pushToTalk.roomJoined}
                                        audioEnabled={pushToTalk.audioEnabled}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        getPushToTalkDepartments={this.getPushToTalkDepartments}
                                        setPttJanusRef={this.setPttJanusRef}
                                        setPttDepartment={this.setPttDepartment}
                                        setPttPluginHandle={this.setPttPluginHandle}
                                        setPttRoomId={this.setPttRoomId}
                                        setPttWebrtcUp={this.setPttWebrtcUp}
                                        setPttRemoteStream={this.setPttRemoteStream}
                                        setPttParticipants={this.setPttParticipants}
                                        setPttRoomJoined={this.setPttRoomJoined}
                                        handlePttTouchUp={this.handlePttTouchUp}
                                        handlePttTouchDown={this.handlePttTouchDown}
                                        setPttFloatingModalVisible={this.setPttFloatingModalVisible}
                                        signOut={this.signOut}
                                    />

                                    {(user?.type === 'superAdmin' || isDepartmentAdminInAnyDepartment) &&
                                        <ProtectedRoute
                                            exact
                                            path='/firetextAnnouncements'
                                            componentType={FiretextAnnouncement}
                                            getAuthenticationStatus={this.getAuthenticationStatus}
                                            accessToken={accessToken}
                                            authenticatedUser={user}
                                            socket={socket}
                                            signOut={this.signOut}
                                            isMobile={isMobile}
                                        />
                                    }
                                    {user?.type !== 'dispatcher' &&
                                        <ProtectedRoute
                                            exact
                                            path='/chat'
                                            componentType={Chat}
                                            getAuthenticationStatus={this.getAuthenticationStatus}
                                            accessToken={accessToken}
                                            user={user}
                                            socket={socket}
                                            signOut={this.signOut}
                                            isMobile={isMobile}
                                        />
                                    }
                                    <ProtectedRoute
                                        exact
                                        path='/history'
                                        componentType={History}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        accessToken={accessToken}
                                        user={user}
                                        socket={socket}
                                        signOut={this.signOut}
                                        isMobile={isMobile}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/maps'
                                        componentType={Maps}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        accessToken={accessToken}
                                        user={user}
                                        socket={socket}
                                        signOut={this.signOut}
                                        isMobile={isMobile}
                                    />
                                    {user?.type === 'superAdmin' &&
                                        <ProtectedRoute
                                            exact
                                            path='/cadDepartments'
                                            componentType={CadDepartments}
                                            getAuthenticationStatus={this.getAuthenticationStatus}
                                            accessToken={accessToken}
                                            user={user}
                                            socket={socket}
                                            signOut={this.signOut}
                                            isMobile={isMobile}
                                        />
                                    }
                                    {showRecordingsTab &&
                                        <ProtectedRoute
                                            exact
                                            path='/firepitchRecordings'
                                            componentType={Recordings}
                                            getAuthenticationStatus={this.getAuthenticationStatus}
                                            accessToken={accessToken}
                                            user={user}
                                            socket={socket}
                                            signOut={this.signOut}
                                            isMobile={isMobile}
                                        />
                                    }
                                    <ProtectedRoute
                                        exact
                                        path='/settings'
                                        componentType={Settings}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        accessToken={accessToken}
                                        user={user}
                                        socket={socket}
                                        setTheme={this.setTheme}
                                        currentTheme={currentTheme}
                                        signOut={this.signOut}
                                        playRadio={this.playRadio}
                                        isMobile={isMobile}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/'
                                        componentType={Auth}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        setToken={this.setToken}
                                        user={user}
                                        signOut={this.signOut}
                                        isMobile={isMobile}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/reset-password'
                                        componentType={Auth}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        setToken={this.setToken}
                                        user={user}
                                        signOut={this.signOut}
                                        isMobile={isMobile}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/department-invites'
                                        componentType={DepartmentInvites}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        accessToken={accessToken}
                                        user={user}
                                        socket={socket}
                                        signOut={this.signOut}
                                        isMobile={isMobile}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path='/firetext-announcement'
                                        componentType={FiretextAnnouncementScreen}
                                        getAuthenticationStatus={this.getAuthenticationStatus}
                                        accessToken={accessToken}
                                        user={user}
                                        socket={socket}
                                        signOut={this.signOut}
                                        isMobile={isMobile}
                                    />
                                    <Route
                                        path='*'
                                        accessToken={accessToken}
                                        render={() =>
                                            <Redirect
                                                to='/'
                                            />
                                        }
                                    />
                                </Switch>
                            </div>
                        </Router>
                    </SnackbarProvider>
                </ThemeProvider >
            )
        }
    }
}

export default App
