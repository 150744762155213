// React
import React from 'react'

// React Router
import { Route, Redirect } from 'react-router-dom'

// Components
import Auth from '../components/login/index'

// Utilities
import AppstoreButtons from '../components/general/AppstoreButtons'

const ProtectedRoute = ({ getAuthenticationStatus, isMobile, ...rest }) => {
    const Component = rest.componentType

    const { activated, type, departments } = getAuthenticationStatus()
    const dashboardAccess = type === 'superAdmin' || type === 'dispatcher' || (departments?.findIndex(department => department.role === 'departmentAdmin') !== -1)

    switch (rest.path) {
        case '/':
            return (
                <Route
                    {...rest}
                    render={(props) => activated ?
                        <>
                            {isMobile ?
                                <AppstoreButtons
                                    signOut={rest.signOut}
                                />
                                :
                                <>
                                    {dashboardAccess ?
                                        <Redirect
                                            to='/dashboard'
                                        />
                                        :
                                        <Redirect
                                            to='/settings'
                                        />
                                    }
                                </>
                            }
                        </>
                        :
                        <Auth
                            {...props}
                            {...rest}
                        />
                    }
                />
            )
        case '/reset-password':
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Component
                            {...props}
                            {...rest}
                        />
                    }
                />
            )
        default:
            return (
                <Route
                    {...rest}
                    render={(props) => activated ?
                        <>
                            {(isMobile && !rest.path?.includes('/long-dispatch-message-public-view') && !rest.path?.includes('/public-audio-dispatch') && !rest.path?.includes('/reset-password/') && !rest.path?.includes('/activate-account/')) ?
                                <AppstoreButtons
                                    signOut={rest.signOut}
                                />
                                :
                                <Component
                                    {...props}
                                    {...rest}
                                />
                            }
                        </>
                        :
                        <Redirect
                            to='/'
                        />
                    }
                />
            )
    }
}

export default ProtectedRoute;