// React
import React, { Component } from 'react'
// Material UI
import {
    Grid, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Typography,
    IconButton, Paper, CircularProgress,
    Link
} from '@material-ui/core'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
// Components
import TablePagination from '../general/TablePagination'
import RecordingPlayer from './RecordingPlayer'
// Utilities
import axios from 'axios'
import moment from 'moment'
import theme from '../../utilities/theme'
import GeneralDropdownField from '../general/DropdownField'

const initialState = {
    recordings: [],
    departments: [],
    loaders: {
        getRecordings: false,
        getDepartments: false
    },
    openRecording: null,
    selectedDepartment: null,
    info: {
        page: 1, //currentPage
        limit: 10, //itemsPerPage
        pagination: {
            "total": 0,
            "limit": 10,
            "currentPage": 1,
            "totalPages": 0,
            "previous": null,
            "next": null
        }
    },
    errors: {
        info: {}
    }
}

class Recordings extends Component {
    constructor(props) {
        super(props)
        this.state = initialState
    }

    componentDidMount = async () => {
        await this.getDepartments()
    }

    // Get departments for filter
    getDepartments = async () => {
        const { accessToken } = this.props
        const { loaders } = this.state
        const { user } = this.props

        if (!loaders.getDepartments) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments', config)
                if (getDepartmentsResponse.data.success) {
                    let departments = getDepartmentsResponse.data.departments

                    // For all users, filter by required feature permissions
                    departments = departments
                        ?.filter(department => department.featuresPermission?.audioDispatches && department.featuresPermission?.audioDispatchBackups)

                    // Filter departments based on user type and permissions
                    if (user?.type !== 'superAdmin') {
                        // For normal users, only show departments where they are a department admin
                        departments = departments
                            ?.filter(dept => this.props.user?.departments?.find(d => d.departmentId === dept._id)?.role === 'departmentAdmin')
                    }

                    this.setState(prevState => ({
                        departments,
                        loaders: { ...prevState.loaders, getDepartments: false },
                    }))
                }
                else {
                    this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: false } }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getDepartments: false }
                }))
            }
        }
    }

    // Get recordings with pagination and department filter
    getRecordings = async () => {
        try {
            const { info, selectedDepartment } = this.state
            const { page, limit, } = info

            this.setState({
                loaders: {
                    ...this.state.loaders,
                    getRecordings: true
                }
            })

            // Build query parameters
            const params = new URLSearchParams()
            params.append('page', page)
            params.append('limit', limit)

            if (selectedDepartment?._id) {
                params.append('departmentId', selectedDepartment?._id)
            }

            const response = await axios.get(`/api/recordings?${params.toString()}`)

            this.setState({
                recordings: response.data.data,
                info: {
                    ...this.state.info,
                    pagination: response.data.pagination
                },
                loaders: {
                    ...this.state.loaders,
                    getRecordings: false
                }
            })
        } catch (err) {
            console.error('Error fetching recordings:', err)
            this.setState({
                loaders: {
                    ...this.state.loaders,
                    getRecordings: false
                }
            })
        }
    }

    // Handle department selection change
    handleDepartmentChange = (_, { value }) => {
        const { departments } = this.state;
        const selectedDepartment = departments.find(d => d._id === value);
        this.setState(prevState => ({
            ...prevState,
            selectedDepartment,
            info: {
                ...prevState.info,
                page: 1 // Reset to first page when changing department
            }
        }), this.getRecordings);
    }

    // Handle pagination change
    handlePageChange = (newPage) => {
        this.setState({
            info: {
                ...this.state.info,
                page: newPage
            }
        }, this.getRecordings)
    }

    // Handle rows per page change
    handleLimitChange = (newLimit) => {
        this.setState({
            info: {
                ...this.state.info,
                limit: parseInt(newLimit),
                page: 1 // Reset to first page when changing limit
            }
        }, this.getRecordings)
    }

    // Format recording name from filename
    formatRecordingName = (fileName) => {
        // Remove file extension
        const nameWithoutExt = fileName.replace(/\.[^/.]+$/, "")

        // Parse date and time from the filename format: rec-DD-MM-YYYY_HH-MM-timestamp.extension
        const parts = nameWithoutExt.split('_')

        if (parts.length >= 2) {
            const datePart = parts[0].replace('rec-', '')
            const timeParts = parts[1].split('-')

            // Try to format as a readable date and time
            try {
                const dateSections = datePart.split('-')
                if (dateSections.length === 3) {
                    const day = dateSections[0]
                    const month = dateSections[1]
                    const year = dateSections[2]

                    const hours = timeParts[0] || '00'
                    const minutes = timeParts[1] || '00'

                    return `Recording from ${day}/${month}/${year} at ${hours}:${minutes}`
                }
            } catch (err) {
                // If parsing fails, return a simplified version
                return `Recording - ${fileName}`
            }
        }

        // If we can't parse it properly, just return the filename
        return `Recording - ${fileName}`
    }

    // Open recording player modal
    openRecordingModal = (recording) => {
        this.setState({ openRecording: recording })
    }

    // Close recording player modal
    closeRecordingModal = () => {
        this.setState({ openRecording: null })
    }

    render() {
        const { recordings, departments, loaders, openRecording, info } = this.state
        const { page, limit, selectedDepartment, pagination } = info

        return (
            <Grid
                style={{ margin: 16 }}
            >
                <Grid
                    container
                    style={{ marginBottom: 8 }}
                >
                    {/* Recordings Table */}
                    {departments?.length > 0 ?
                        <>
                            {/* Department Selection */}
                            <Grid
                                container
                                style={{ marginBottom: 8 }}
                            >
                                <Typography variant="h6" >
                                    Select Department *
                                </Typography>
                                <GeneralDropdownField
                                    width={12}
                                    options={
                                        departments?.sort((a, b) => a.name.localeCompare(b.name))?.map(department => ({
                                            value: department._id,
                                            text: `${department.name} ${department.agency ?? ''}`
                                        })
                                        )}
                                    format="autocomplete"
                                    placeholder='Select a department...'
                                    statePath={`selectedDepartment`}
                                    value={selectedDepartment?._id}
                                    handleInputChange={this.handleDepartmentChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <TableContainer>
                                        {loaders.getRecordings ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
                                                <CircularProgress />
                                            </div>
                                        ) : recordings.length === 0 ? (
                                            <div style={{ textAlign: 'center', padding: '40px' }}>
                                                <AudiotrackIcon style={{ fontSize: 60, color: theme.palette.grey[400] }} />
                                                <Typography variant="h6" color="textSecondary">
                                                    No recordings found
                                                </Typography>
                                                {!selectedDepartment?._id && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        Please select a department to view recordings
                                                    </Typography>
                                                )}
                                            </div>
                                        ) : (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Recording</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Date Added</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {recordings?.map((recording) => (
                                                        <TableRow
                                                            key={recording._id}
                                                            hover
                                                            onClick={() => this.openRecordingModal(recording)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell>{recording.fileName}</TableCell>
                                                            <TableCell>{this.formatRecordingName(recording.fileName)}</TableCell>
                                                            <TableCell>
                                                                {moment(recording.createdAt).format('MMM DD, YYYY [at] h:mm A')}
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.openRecordingModal(recording);
                                                                    }}
                                                                >
                                                                    <AudiotrackIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </TableContainer>

                                    {/* Pagination */}
                                    {pagination && pagination.total > 0 && (
                                        <TablePagination
                                            total={pagination.total}
                                            rowsPerPage={pagination.limit || limit}
                                            page={pagination.currentPage || page}
                                            handleChangePage={this.handlePageChange}
                                            handleChangeRowsPerPage={this.handleLimitChange}
                                        />
                                    )}
                                </Paper>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 16 }}>
                            <Typography variant="h6" color="textSecondary" >
                                None of the departments have radio recording permission.
                                <br />
                                Please contact <Link href="https://firetext.net/support" target="_blank" style={{ color: theme.palette.secondary.main }}> FireTEXT® Support </Link> to enable radio recordings.
                            </Typography>
                        </Grid>
                    }

                    {/* Recording Player Modal */}
                    {openRecording && (
                        <RecordingPlayer
                            open={Boolean(openRecording)}
                            onClose={this.closeRecordingModal}
                            recording={openRecording}
                        />
                    )}
                </Grid>
            </Grid>

        )
    }
}

export default Recordings 
