import React from 'react'

const RemoteStreamPlayer = ({ remoteStream }) => {
    return (
        <>
            <audio
                id="roomaudio"
                autoPlay
                controls
                ref={(audioElement) => {
                    if (audioElement && remoteStream) {
                        audioElement.srcObject = remoteStream
                    }
                }}
                style={{ visibility: 'hidden', display: 'none' }}
            />
        </>
    )
}

export default RemoteStreamPlayer;
