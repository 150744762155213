import React, { Component, useEffect, useState } from 'react'
import { Prompt } from "react-router-dom"

//material ui
import { Button, Grid, Typography, IconButton, Tooltip, CircularProgress, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tabs, Tab, withStyles, styled } from "@material-ui/core"
import AddBoxIcon from '@material-ui/icons/AddBox'
import { withSnackbar } from 'notistack'

// Components
import HelpIcon from '@material-ui/icons/Help'
import RemoveIcon from '@material-ui/icons/Remove'
import SettingsIcon from '@material-ui/icons/Settings'
import GeneralTextField from "../general/TextField"
import GeneralDropdownField from "../general/DropdownField"
import GeneralCheckboxField from '../general/CheckboxField'
import TablePagination from '../general/TablePagination'
import CreateNewUserModal from './users/CreateNewUserModal/index'
import EditUserModal from './users/EditUserModal'

// Utilities
import axios from 'axios'
import { StateOptions } from '../../utilities/info'
import { handleInputChange, handleErrors } from "../../utilities/handleChange"
import { Email, ValidateAndTransformToBackendPhoneNumber } from '../../utilities/Validate'
import { Timezones, ToneTypes, UserRolesInDepartment } from '../../utilities/Options'
import { OpenSnackBar } from '../../utilities/handleSnackBar'
import { maskPhoneNumber } from '../../utilities/Helpers'
import { sortTableData } from '../../utilities/sorthelper'
import ToggleIconButton from '../../utilities/togglebutton'
import UpgradeToPremium from '../general/upgradeToPremium'
import theme from '../../utilities/theme'
import UserRegistrationRequestDialog from './users/UserRegistrationRequestDialog'


const initialState = {
    currentTab: 'info',
    administeredDepartments: [],
    callTypes: [],
    defaultCallTypes: [],
    isDepartmentUnsaved: false,
    errors: {
        administeredDepartments: null,
        info: {

        },
        selectedDepartment: {
            name: null,
            fdid: null,
            phone: null,
            callTypes: [],
            color: null,
            location: {
                address: {

                }
            },
            tones: [],

        },
        user: {
            departments: []
        }
    },
    info: {
        search: '',
        page: 1, //currentPage
        limit: 25, //itemsPerPage
        pagination: {
            "total": 0,
            "limit": 25,
            "currentPage": 1,
            "totalPages": 0,
            "previous": null,
            "next": null
        }
    },
    loaders: {
        getDepartments: false,
        getUsers: false,
        getCallTypes: false,
        updateDepartment: false,
        createNewUser: false,
        updateUser: false,
        deleteUser: false,
        resendActivationLink: false,
        sendTestSms: false
    },
    modals: {
        createNewUser: false,
        editUser: false
    },
    nonDefaultCallTypes: [],
    selectedDepartmentKey: Math.random(),
    selectedDepartment: null,
    selectedDepartmentUsers: [],
    user: {
        email: null,
        type: null,
        departments: [],
        deactivated: false,
        phoneNumberOnly: false,
        notifications: {
            sms: 2,
            phoneCall: 1,
            push: 1,
            email: 1,
            availability: {
                type: "always",
                timeslots: []
            }
        }
    },
}

class Department extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            sortedData: [],             // Your existing state properties
            sortedColumn: '',         // Initial sorted column
            sortOrder: ' ',             // Initial sorting order
        }
    }

    componentDidMount = async () => {
        window.addEventListener("beforeunload", this.beforeunload)
        this.getDepartments()
        this.getCallTypes()
    }

    handleColumnHeaderClick = (column) => {
        if (column === this.state.sortedColumn) {
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc'
            }))
        } else {
            this.setState({
                sortedColumn: column,
                sortOrder: 'asc', // Reset sortOrder when sorting a new column
            })
        }
    }

    componentWillUnmount() {
        this.props.closeSnackbar()
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        if (this.state.isDepartmentUnsaved) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    setUnsavedChangesFlags = (flag) => {
        const { setDepartmentInfoUnsavedFlag } = this.props
        const { isDepartmentUnsaved } = this.state

        if (flag && !isDepartmentUnsaved) {
            this.setState({ isDepartmentUnsaved: true })//, setParentFlagToPreventTabChange)
            if (!this.props.isDepartmentInfoUnsaved) {
                setDepartmentInfoUnsavedFlag(true)
            }
        }
        else if (!flag && isDepartmentUnsaved) {
            this.setState({ isDepartmentUnsaved: false })
            if (this.props.isDepartmentInfoUnsaved) {
                setDepartmentInfoUnsavedFlag(false)
            }
        }
    }

    // helpers
    addToneField = () => {
        let value = 'selectedDepartment'

        this.setState(prevState => ({
            [value]: {
                ...prevState[value],
                tones: prevState[value].tones.concat({
                    type: null,
                    value: null,
                    variance: null,
                    length: null
                })
            },
            errors: {
                ...prevState.errors,
                [value]: {
                    ...prevState.errors[value],
                    tones: prevState.errors[value].tones.concat({
                        type: null,
                        value: null,
                        variance: null,
                        length: null
                    })
                }
            }
        }), () => this.setUnsavedChangesFlags(true))
    }

    deleteToneField = (index) => {
        let value = 'selectedDepartment'

        this.setState(prevState => ({
            [value]: {
                ...prevState[value],
                tones: prevState[value].tones.filter((tone, i) => {
                    return i !== index
                })
            },
            errors: {
                ...prevState.errors,
                [value]: {
                    ...prevState.errors[value],
                    tones: prevState.errors[value].tones.filter((tone, i) => {
                        return i !== index
                    })
                }
            }
        }), () => this.setUnsavedChangesFlags(true))
    }

    handleInputChange = (e, data, callback = null) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleDepartmentInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => this.setUnsavedChangesFlags(true))
    }

    handleTabChange = (e, value) => {
        if (this.handleErrorsOnTabs(this.state.currentTab))
            this.setState({ currentTab: value })
    }

    handleDepartmentInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => this.setUnsavedChangesFlags(true))
    }

    handleFloatInputChange = (e, data) => {
        const parseFloat = (str, decimalPoints = 2) => {
            str = str.toString()
            str = str.slice(0, str.includes(".") ? ((str.indexOf(".")) + decimalPoints + 1) : str.length)
            return str
        }
        this.setState(handleInputChange(e, { ...data, value: parseFloat(data.value || "") }, this.state), () => this.setUnsavedChangesFlags(true))
    }

    handleDepartmentChange = async (e, data) => {
        const { isDepartmentUnsaved } = this.state
        if (isDepartmentUnsaved) {
            let leaveTab = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!leaveTab)
                return this.setState({ selectedDepartmentKey: Math.random() })
        }
        const { administeredDepartments } = this.state
        const selectedDepartment = administeredDepartments.find(department => department._id === data.value)
        if (selectedDepartment) {
            this.setState(handleInputChange(e, { ...data, value: selectedDepartment }, this.state), () => {
                this.setState(prevState => ({
                    selectedDepartmentKey: Math.random(),
                    selectedDepartmentUsers: [],
                    isDepartmentUnsaved: false,
                    currentTab: 'info',
                    errors: {
                        ...prevState.errors,
                        selectedDepartment: {
                            ...initialState.errors.selectedDepartment,
                            callTypes: this.state.selectedDepartment.callTypes.map(callType => ({ callTypeId: null })),
                            tones: this.state.selectedDepartment.tones.map(tone => ({ type: null, value: null, variance: null, length: null }))
                        }
                    }
                }), this.getUsers)
            }
            )
        }
    }

    handleColorChange = (color) => {
        this.setState(prevState => ({
            selectedDepartment: {
                ...prevState.selectedDepartment,
                color: color.hex
            }
        }))
    }

    filterAdministeredDepartments = (departments) => {
        const { authenticatedUser } = this.props
        const administeredDepartmentsIds = authenticatedUser?.departments?.filter(department => department.role === 'departmentAdmin').map(department => department.departmentId) || []
        const administeredDepartments = departments.filter(department => administeredDepartmentsIds.includes(department._id))
        return administeredDepartments
    }

    getSpecificDepartmentRoleForUser = user => ([...user.departments, ...(user.invites ?? [])].find(d => d.departmentId === this.state.selectedDepartment._id)?.role)
    getSpecificDepartmentEditMapPermissions = user => ([...user.departments, ...(user.invites ?? [])].find(d => d.departmentId === this.state.selectedDepartment._id)?.editMapsPermission)

    handleSpecificDepartmentRoleChange = (e, data) => {
        const { selectedDepartment, user } = this.state
        const departments = [...user.departments]

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: departments.map(department => {
                    if (department.departmentId === selectedDepartment._id)
                        return {
                            ...department,
                            role: data.value
                        }
                    return department
                })
            }
        }))

    }

    handleSpecificDepartmentEditMapPermissions = (e, data) => {
        const { selectedDepartment, user } = this.state
        const departments = [...user.departments]

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: departments.map(department => {
                    if (department.departmentId === selectedDepartment._id)
                        return {
                            ...department,
                            editMapsPermission: data.value
                        }
                    return department
                })
            }
        }))

    }

    handleAddCallType = () => this.setState(prevState => ({
        selectedDepartment: {
            ...prevState.selectedDepartment,
            callTypes: [
                ...prevState.selectedDepartment.callTypes,
                { callTypeId: null }
            ]
        },
        errors: {
            ...prevState.errors,
            selectedDepartment: {
                ...prevState.errors.selectedDepartment,
                callTypes: [...prevState.errors.selectedDepartment.callTypes, { callTypeId: null }]
            }
        }
    }), () => this.setUnsavedChangesFlags(true))

    handleDeleteCallType = callTypeId => {
        const callTypes = this.state.selectedDepartment.callTypes.filter(callType => callType.callTypeId !== callTypeId)
        return this.setState(prevState => ({
            selectedDepartment: {
                ...prevState.selectedDepartment,
                callTypes
            },
            errors: {
                ...prevState.errors,
                selectedDepartment: {
                    ...prevState.errors.selectedDepartment,
                    callTypes: callTypes.map(ct => ({ callTypeId: null }))
                }
            }
        }), () => this.setUnsavedChangesFlags(true))
    }

    handleErrorsOnTabs = tab => {
        const { success, errors } = this.handleErrors(tab)
        if (success) return true
        else {
            alert('Please fill out all of the required fields.')
            this.setState({ errors })
            return false
        }
    }

    handleErrors = tab => {
        const { selectedDepartment } = this.state
        // note: array index corresponds to current tab on Create Department modal.
        const infoTabFields = [
            {
                path: 'selectedDepartment.name',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'selectedDepartment.fdid',
                type: 'string',
                shouldCheck: false
            },
            {
                path: 'selectedDepartment.phone',
                type: 'string',
                shouldCheck: !!selectedDepartment.phone,
                validation: !!ValidateAndTransformToBackendPhoneNumber(selectedDepartment.phone),
                validationMessage: "Please provide a valid phone number."
            },
            {
                path: 'selectedDepartment.type',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'selectedDepartment.timezone',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'selectedDepartment.location.address.addressLine1',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'selectedDepartment.location.address.city',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'selectedDepartment.location.address.state',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'selectedDepartment.location.address.zip',
                type: 'string',
                shouldCheck: true
            }
        ]
        const calltypesTabFields = [
            {
                path: 'selectedDepartment.callTypes',
                type: 'objectArray',
                keys: {
                    callTypeId: {
                        type: 'string',
                        shouldCheck: true
                    }
                },
                shouldCheck: selectedDepartment.callTypes?.length > 0
            }
        ]
        const firepitchTabFields = [
            {
                path: 'selectedDepartment.tones',
                type: 'objectArray',
                keys: {
                    length: {
                        shouldCheck: true
                    },
                    type: {
                        type: 'string',
                        shouldCheck: true
                    },
                    value: {
                        shouldCheck: true
                    },
                    variance: {
                        shouldCheck: true
                    }
                },
                shouldCheck: selectedDepartment.tones?.length > 0
            },
            {
                path: 'selectedDepartment.alertDepartmentForFirepitchContactNumber',
                type: 'string',
                shouldCheck: selectedDepartment.alertDepartmentForFirepitch,
                validation: !!ValidateAndTransformToBackendPhoneNumber(selectedDepartment.alertDepartmentForFirepitchContactNumber),
                validationMessage: "Please provide a valid phone number.",
            }
        ]

        let fieldsByTab = {
            'info': infoTabFields,
            'calltypes': calltypesTabFields,
            'firepitch': firepitchTabFields
        }
        let allFields = [...infoTabFields, ...calltypesTabFields, ...firepitchTabFields]
        let errors = { ...this.state.errors }
        let frontEndErrors = handleErrors(this.state, errors, tab ? fieldsByTab[tab] : allFields)
        return frontEndErrors
    }

    handleUserErrors = (requestType) => {
        const { user } = this.state
        let fields = []

        if (requestType === 'create') {
            fields = [
                {
                    path: 'user.email',
                    type: 'string',
                    shouldCheck: user.type !== 'user' || (user.type === 'user' && !user.phone),
                    errorMessage: 'Provide email or phone number',
                    validation: Email(user.email),
                    validationMessage: "Invalid email"
                },
                {
                    path: 'user.phone',
                    type: 'string',
                    shouldCheck: user.type === 'user' && !user.email,
                    errorMessage: 'Provide email or phone number',
                    validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
                    validationMessage: "Please provide a valid phone number."
                },
                {
                    path: 'user.type',
                    type: 'string',
                    shouldCheck: true,
                    validation: user.type === 'user',
                    validationMessage: 'Invalid user type'
                },
                {
                    path: 'user.departments',
                    type: 'objectArray',
                    keys: {
                        departmentId: {
                            type: 'string',
                            shouldCheck: true
                        },
                        role: {
                            type: 'string',
                            shouldCheck: true,
                            validate: value => (value === 'departmentAdmin' || value === 'subscriber'),
                            validationMessage: 'Invalid user role in department'
                        }
                    },
                    shouldCheck: true
                },
                {
                    path: 'user.firstName',
                    type: 'string',
                    shouldCheck: user.phoneNumberOnly === true
                },
                {
                    path: 'user.lastName',
                    type: 'string',
                    shouldCheck: user.phoneNumberOnly === true
                },
            ]
        }
        else if (requestType === 'update') {
            fields = [
                {
                    path: 'user.email',
                    type: 'string',
                    shouldCheck: !user.phoneNumberOnly,
                    validation: Email(user.email),
                    validationMessage: "Invalid email"
                },
                {
                    path: 'user.phone',
                    type: 'string',
                    shouldCheck: true,
                    validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
                    validationMessage: "Please provide a valid phone number."
                },
                {
                    path: 'user.firstName',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.lastName',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.departments',
                    type: 'objectArray',
                    keys: {
                        departmentId: {
                            type: 'string',
                            shouldCheck: true
                        },
                        role: {
                            type: 'string',
                            shouldCheck: true,
                            validate: value => (value === 'departmentAdmin' || value === 'subscriber'),
                            validationMessage: 'Invalid user role in department'
                        }
                    },
                    shouldCheck: !!user.departments?.length
                }
            ]
        }

        let errors = { ...this.state.errors }
        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    // CRUD
    getDepartments = async () => {
        const { accessToken } = this.props
        const { selectedDepartment, loaders } = this.state

        if (!loaders.getDepartments) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments', config)
                if (getDepartmentsResponse.data.success) {
                    const administeredDepartments = this.filterAdministeredDepartments(getDepartmentsResponse.data.departments || [])
                    this.setState(prevState => ({
                        administeredDepartments,
                        selectedDepartment: administeredDepartments.find(dept => dept?._id === selectedDepartment?._id) || administeredDepartments[0],
                        loaders: { ...prevState.loaders, getDepartments: false },
                        errors: {
                            ...prevState.errors,
                            selectedDepartment: {
                                ...prevState.errors.selectedDepartment,
                                callTypes: administeredDepartments[0].callTypes.map(callType => ({ callTypeId: null })),
                                tones: administeredDepartments[0].tones.map(tone => ({ type: null, value: null, variance: null, length: null }))
                            }
                        }
                    }), this.getUsers)
                }
                else {
                    this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: false } }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getDepartments: false }
                }))
            }
        }
    }

    getUsers = async (queryType = 'department') => {
        const { selectedDepartment, loaders, info } = this.state
        const { page, limit, search } = info
        const { accessToken } = this.props

        if (!selectedDepartment) return

        if (!loaders.getUsers) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getUsers: true } }))
            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    },
                    params: {
                        page,
                        limit,
                        search,
                        queryType: queryType,
                        departmentId: selectedDepartment._id
                    }
                }
                const getUsersResponse = await axios.get('/api/users', config)
                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data.success) {
                    const selectedDepartmentUsers = [...getUsersResponse.data.users]
                    let pagination = getUsersResponse.data.pagination || initialState.info.pagination

                    this.setState(prevState => ({
                        selectedDepartmentUsers,
                        info: { ...prevState.info, pagination },
                        loaders: { ...prevState.loaders, getUsers: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getUsersResponse.data.errors },
                        loaders: { ...prevState.loaders, getUsers: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getUsers: false }
                }))
            }
        }
    }

    getCallTypes = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getCallTypes) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getCallTypes: true } }))
            try {
                const getCallTypesResponse = await axios.get('/api/calltypes', config)
                if (getCallTypesResponse && getCallTypesResponse.data && getCallTypesResponse.data.success) {
                    let callTypes = getCallTypesResponse.data.callTypes
                    const defaultCallTypes = callTypes.filter(callType => callType.default).map(callType => ({ text: callType.callType, value: callType._id }))
                    const nonDefaultCallTypes = callTypes.filter(callType => !callType.default).map(callType => ({ text: callType.callType, value: callType._id }))
                    this.setState(prevState => ({
                        callTypes,
                        defaultCallTypes,
                        nonDefaultCallTypes,
                        loaders: { ...prevState.loaders, getCallTypes: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getCallTypesResponse.data.errors },
                        loaders: { ...prevState.loaders, getCallTypes: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getCallTypes: false }
                }))
            }
        }
    }

    updateDepartment = async () => {
        const { selectedDepartment, loaders } = this.state
        const config = {
            headers: {
                Authorization: this.props.accessToken
            }
        }

        if (!loaders.updateDepartment) {
            const { success, errors } = this.handleErrors()

            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateDepartment: true } }))

                try {
                    let formattedDepartment = {
                        ...selectedDepartment,
                        phone: ValidateAndTransformToBackendPhoneNumber(selectedDepartment.phone),
                        tones: (selectedDepartment?.tones || []).map(tone => ({ ...tone, variance: parseFloat(tone.variance), value: parseFloat(tone.value), length: parseFloat(tone.length) })),
                        alertDepartmentForFirepitchContactNumber: ValidateAndTransformToBackendPhoneNumber(selectedDepartment.alertDepartmentForFirepitchContactNumber)
                    }
                    const updateDepartmentResponse = await axios.put(`/api/departments/${selectedDepartment._id}`, formattedDepartment, config)

                    if (updateDepartmentResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateDepartment: false },
                            selectedDepartment: { ...updateDepartmentResponse.data.department }
                        }), () => {
                            this.setUnsavedChangesFlags(false)
                            this.getDepartments()
                        })
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Department updated successfully", "success")
                    } else {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, updateDepartment: false } }))
                    }
                }
                catch (e) {
                    this.setState(prevState => ({ loaders: { ...prevState.loaders, updateDepartment: false } }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    createUser = async () => {
        let { loaders, user } = this.state

        if (!loaders.createNewUser) {

            const { success, errors } = this.handleUserErrors('create')
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewUser: true } }))
                try {
                    const { accessToken } = this.props
                    const config = {
                        headers: {
                            Authorization: accessToken
                        }
                    }
                    let formattedUser = !user.phoneNumberOnly ?
                        {
                            ...user,
                            phone: ValidateAndTransformToBackendPhoneNumber(user.phone)
                        }
                        :
                        {
                            phoneNumberOnly: user.phoneNumberOnly,
                            email: null,
                            phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
                            departments: user.departments,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            notifications: user.notifications,
                            type: user.type
                        }

                    const createUserResponse = await axios.post('/api/users', formattedUser, config) // requestType: createAccount sends the fully formatted email where no request just sends an email confirmation link
                    if (createUserResponse?.data?.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewUser: false },
                            selectedDepartmentUsers: [...prevState.selectedDepartmentUsers, createUserResponse.data.user]
                        }))
                        this.handleModal('createNewUser')
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User created successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createUserResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewUser: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewUser: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateUser = async (type) => {
        const { user, loaders } = this.state
        const config = {
            headers: {
                Authorization: this.props.accessToken
            }
        }

        if (!loaders.updateUser) {
            const { success, errors } = this.handleUserErrors('update')
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateUser: true } }))

                // reformat the deparments before sending to put route
                let formattedUser = {
                    ...user,
                    phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
                    deactivated: type === 'deactivate' ? true : type === 'reactivate' ? false : user.deactivated
                }
                try {
                    let updateUserResponse = await axios.put(`/api/users/${user._id}`, formattedUser, config)
                    // console.log({ updateUserResponse })

                    if (updateUserResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateUser: false },
                            // modals: { ...prevState.modals, editUser: false }
                        }), this.getUsers)
                        if (updateUserResponse.data?.responseMessage) {
                            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, updateUserResponse.data.responseMessage.text, updateUserResponse.data.responseMessage.type)
                        }
                        else {
                            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User updated successfully", "success")
                        }
                    } else {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateUser: false },
                        }))
                    }

                    return true

                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, updateUser: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    deleteUser = async () => {
        const { loaders, user, selectedDepartment } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            },
            params: {
                departmentId: selectedDepartment._id
            }
        }

        if (!loaders.deleteUser) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteUser: true } }))
            try {
                const deleteUserResponse = await axios.delete(`/api/users/${user._id}`, config)
                if (deleteUserResponse && deleteUserResponse.data && deleteUserResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteUser: false }
                    }))
                    this.handleModal('editUser')
                    this.getUsers()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteUserResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteUser: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteUser: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    handleResendActivationLink = async (user) => {
        if (!this.state.loaders.resendActivationLink) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendActivationLink: true } }))
            try {
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                const userInformation = {
                    userId: user._id
                }

                let resendActivationLinkResponse = await axios.post(`/api/users/resend-activation`, { ...userInformation }, config)
                // console.log({ resendActivationLinkResponse })

                if (!!resendActivationLinkResponse?.data?.success) {
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Resent activation link to ${user.email}`, "success")
                }
            }
            catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to resend activation link", "error")
            }
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendActivationLink: false } }))
        }
    }

    handleResendDepartmentInviteLink = async (user) => {
        if (!this.state.loaders.resendDepartmentInviteLink) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendDepartmentInviteLink: true } }))
            try {
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                const userInformation = {
                    userId: user._id,
                    departmentIdToInvite: this.state.selectedDepartment._id
                }

                let resendDepartmentInviteLinkResponse = await axios.post(`/api/users/resend-department-invite`, { ...userInformation }, config)
                // console.log({ resendDepartmentInviteLinkResponse })

                if (!!resendDepartmentInviteLinkResponse?.data?.success) {
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Resent invitation link to ${user.email}`, "success")
                }
            }
            catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to resend invitation link", "error")
            }
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendDepartmentInviteLink: false } }))
        }
    }

    handleSendTestSms = async (userId) => {
        if (this.state.loaders.sendTestSms) return
        if (!userId) return

        this.setState(prevState => ({ loaders: { ...prevState.loaders, sendTestSms: true } }))

        try {
            const config = {
                headers: {
                    Authorization: this.props.accessToken
                }
            }

            let sendTestSmsResponse = await axios.post(`/api/users/${userId}/send-sms`, null, config)

            if (sendTestSmsResponse && sendTestSmsResponse.data && sendTestSmsResponse.data.success) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `SMS delivered to the carrier.`, "success")
            }

        } catch (error) {
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to send SMS", "error")
        }

        this.setState(prevState => ({ loaders: { ...prevState.loaders, sendTestSms: false } }))
    }

    //pagination methods
    handleChangePage = (page) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page
            }
        }), this.getUsers)
    }

    handleChangeRowsPerPage = (limit) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1,
                limit
            }
        }), this.getUsers)
    }

    //modal methods
    handleModal = (modal, user) => {
        const { selectedDepartment, modals } = this.state

        switch (modal) {
            case 'createNewUser':
                if (modals.createNewUser) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        user: { ...initialState.user },
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        user: {
                            ...initialState.user,
                            type: 'user',
                            departments: [{
                                departmentId: selectedDepartment._id,
                                role: UserRolesInDepartment.find(type => type.text === 'Subscriber')?.value
                            }]
                        },
                        errors: {
                            ...initialState.errors,
                            user: {
                                ...initialState.errors.user,
                                departments: [{ departmentId: null, role: null }]
                            }
                        }
                    }))
                }
            case 'editUser':
                // Reset user if open, set user details if not open, and close modal.
                if (modals.editUser) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editUser: false },
                        user: initialState.user,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editUser: true },
                        user,
                        errors: {
                            ...initialState.errors,
                            user: {
                                ...initialState.errors.user,
                                departments: user.departments.map(department => { return { deactivated: null, departmentId: null, role: null } })
                            }
                        }
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    searchUsers = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1
            }
        }), this.getUsers)
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ''
            }
        }), this.getUsers)
    }

    render() {
        const { authenticatedUser } = this.props
        const { currentTab, administeredDepartments, selectedDepartment, defaultCallTypes, nonDefaultCallTypes, selectedDepartmentUsers, info, modals, user, loaders, errors, isDepartmentUnsaved, selectedDepartmentKey, sortedColumn, sortOrder } = this.state
        const { pagination, limit, page } = info
        let sortedUsers = selectedDepartmentUsers
        if (sortedColumn && sortOrder) {
            sortedUsers = sortTableData(selectedDepartmentUsers, sortedColumn, sortOrder)
        }
        if (loaders.getDepartments === true)
            return (
                <Grid
                    item
                    xs={12}
                    style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: 96 }}
                >
                    <CircularProgress
                        size={36}
                    />
                </Grid>
            )

        if (!selectedDepartment)
            return (
                <Grid
                    item
                    xs={12}
                    style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: 96 }}
                >
                    Department can't be reached at the moment. Please, try again.
                </Grid>
            )

        const selectedDepartmentCallTypeIds = selectedDepartment.callTypes.map(callType => callType.callTypeId)
        const callTypeOptions = [...nonDefaultCallTypes, ...defaultCallTypes].map(callType => {
            if (selectedDepartmentCallTypeIds.includes(callType.value))
                return { ...callType, disabled: true }
            return callType
        })

        return (
            <>
                <Prompt
                    when={isDepartmentUnsaved}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />

                {/* Modals */}
                {modals.createNewUser &&
                    <CreateNewUserModal
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('createNewUser')}
                        createUser={this.createUser}
                        authenticatedUser={authenticatedUser}
                        user={user}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewUser}
                        isDepartmentAdmin={true}
                    />
                }
                {modals.editUser &&
                    <EditUserModal
                        handleResendActivationLink={this.handleResendActivationLink}
                        handleResendDepartmentInviteLink={!!user?.invites?.filter(inv => inv.departmentId === selectedDepartment._id)?.length ? this.handleResendDepartmentInviteLink : null}
                        handleSendTestSms={this.handleSendTestSms}
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('editUser')}
                        deleteUser={this.deleteUser}
                        user={user}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editUser}
                        updateUser={this.updateUser}
                        authenticatedUser={authenticatedUser}
                        handleSpecificDepartmentRoleChange={this.handleSpecificDepartmentRoleChange}
                        handleSpecificDepartmentEditMapPermissions={this.handleSpecificDepartmentEditMapPermissions}
                        specificDepartmentRole={this.getSpecificDepartmentRoleForUser(user)}
                        specificDepartmentEditMapPermissions={this.getSpecificDepartmentEditMapPermissions(user)}
                    />
                }

                {/* Select Department Dropdown */}
                {administeredDepartments.length > 1 &&
                    <Grid
                        spacing={2}
                        container
                        style={{ padding: 16, paddingBottom: 0 }}
                    >
                        <Typography style={{ width: "100%" }} variant="h6" gutterBottom>
                            Select Department *
                        </Typography>
                        <GeneralDropdownField
                            width={12}
                            key={selectedDepartmentKey}
                            options={
                                administeredDepartments.map(department => ({
                                    value: department._id,
                                    text: `${department.name} ${department.agency ?? ''}`
                                })
                                )}
                            format="autocomplete"
                            placeholder='Select a department...'
                            statePath={`selectedDepartment`}
                            value={selectedDepartment._id}
                            handleInputChange={this.handleDepartmentChange}
                        />
                    </Grid>
                }

                {/* Menu */}
                <Grid
                    spacing={2}
                    container
                    style={{ padding: 16 }}
                >
                    <Typography style={{ width: "100%" }} variant="h6" gutterBottom>
                        Edit Department Information
                    </Typography>
                    <Tabs
                        value={currentTab}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="edit department tabs"
                    >
                        <Tab label="Info" value="info" style={{ opacity: 1 }} />
                        <Tab label="CallTypes" value="calltypes" style={{ opacity: 1 }} />
                        <Tab label="FirePITCH" value="firepitch" style={{ opacity: 1 }} />
                        {!selectedDepartment.allCall &&
                            <Tab label="Users" value="users" style={{ opacity: 1 }} />
                        }
                    </Tabs>
                </Grid>

                { // info
                    currentTab === 'info' &&
                    <Grid
                        spacing={2}
                        container
                        style={{ padding: 16 }}
                    >
                        <GeneralTextField
                            autoFocus={true}
                            width={6}
                            disabled={true}
                            label='Name *'
                            placeholder="Enter department name..."
                            statePath={`selectedDepartment.name`}
                            value={selectedDepartment.name}
                            error={errors.selectedDepartment.name}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralTextField
                            width={6}
                            format='phoneNumber'
                            label='Phone Number'
                            placeholder={`Enter department's phone number...`}
                            statePath={`selectedDepartment.phone`}
                            value={selectedDepartment.phone}
                            error={errors.selectedDepartment.phone}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralDropdownField
                            options={Timezones}
                            format="autocomplete"
                            width={6}
                            label='Timezone *'
                            placeholder='Enter department timezone...'
                            statePath={`selectedDepartment.timezone`}
                            value={selectedDepartment.timezone}
                            error={errors.selectedDepartment.timezone}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralTextField
                            width={6}
                            label='FDID'
                            placeholder="Enter Fdid assigned to your department..."
                            statePath={`selectedDepartment.fdid`}
                            value={selectedDepartment.fdid}
                            error={errors.selectedDepartment.fdid}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralTextField
                            width={6}
                            label='Address Line 1 *'
                            placeholder='Enter address line 1...'
                            statePath={`selectedDepartment.location.address.addressLine1`}
                            value={selectedDepartment.location.address.addressLine1}
                            error={errors.selectedDepartment.location.address.addressLine1}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralTextField
                            width={6}
                            label='Address Line 2'
                            placeholder='Enter address line 2...'
                            statePath={`selectedDepartment.location.address.addressLine2`}
                            value={selectedDepartment.location.address.addressLine2}
                            error={errors.selectedDepartment.location.address.addressLine2}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralTextField
                            width={6}
                            label='City *'
                            placeholder='Enter city...'
                            statePath={`selectedDepartment.location.address.city`}
                            value={selectedDepartment.location.address.city}
                            error={errors.selectedDepartment.location.address.city}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralDropdownField
                            options={StateOptions}
                            format="autocomplete"
                            width={3}
                            label='State *'
                            placeholder='Enter state...'
                            statePath={`selectedDepartment.location.address.state`}
                            value={selectedDepartment.location.address.state}
                            error={errors.selectedDepartment.location.address.state}
                            handleInputChange={this.handleDepartmentInputChange}
                        />
                        <GeneralTextField
                            width={3}
                            label='Zip Code *'
                            placeholder='Enter zip code...'
                            statePath={`selectedDepartment.location.address.zip`}
                            value={selectedDepartment.location.address.zip}
                            error={errors.selectedDepartment.location.address.zip}
                            handleInputChange={this.handleDepartmentInputChange}
                        />

                    </Grid>
                }
                { // Call Types
                    currentTab === 'calltypes' &&
                    <Grid
                        spacing={2}
                        container
                        style={{ padding: 16 }}
                    >
                        <Grid
                            xs={12}
                            container
                            item
                            style={{ marginTop: 16 }}
                        >
                            <Typography
                                variant='h6'
                            >
                                Configure Call Types
                            </Typography>
                            <Tooltip
                                style={{ marginLeft: 8 }}
                                title='Configure the available call types for this department. Some call types are attached to every department and only the mutual aid departments can be updated for that call type.'
                            >
                                <HelpIcon />
                            </Tooltip>
                        </Grid>
                        {loaders.getCallTypes ? (
                            <Grid
                                item
                                xs={12}
                                style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: 16 }}
                            >
                                <CircularProgress
                                    size={18}
                                />
                            </Grid>
                        ) : (
                            selectedDepartment.callTypes.map((callType, index) => {
                                return (
                                    <>
                                        <GeneralDropdownField
                                            key={selectedDepartment.callTypes[index].callTypeId}
                                            options={callTypeOptions}
                                            format="autocomplete"
                                            width={selectedDepartment.callTypes.length === 0 ? 12 : 11}
                                            label={`Call Type`}
                                            hideLabel={index !== 0}
                                            placeholder='Select a department...'
                                            statePath={`selectedDepartment.callTypes.${index}.callTypeId`}
                                            value={selectedDepartment.callTypes[index].callTypeId}
                                            handleInputChange={this.handleDepartmentInputChange}
                                            error={errors.selectedDepartment.callTypes[index]?.callTypeId}
                                        />
                                        {(selectedDepartment.callTypes.length > 1) &&
                                            <Grid
                                                item
                                                xs={1}
                                                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                                            >
                                                <IconButton
                                                    aria-label='close'
                                                    onClick={() => this.handleDeleteCallType(selectedDepartment.callTypes[index].callTypeId)}
                                                    disabled={loaders.updateDepartment}
                                                >
                                                    <RemoveIcon style={{ color: '#ff0000' }} />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </>
                                )
                            })
                        )}
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                onClick={this.handleAddCallType}
                                disabled={loaders.updateDepartment}
                                color='primary'
                                variant='outlined'
                            >
                                Add Another Call Type
                            </Button>
                        </Grid>

                    </Grid>
                }
                {/* Audio Tones */}
                {currentTab === 'firepitch' && selectedDepartment.featuresPermission?.audioDispatches &&
                    <Grid
                        spacing={2}
                        container
                        style={{ padding: 16 }}
                    >
                        <Grid
                            xs={12}
                            container
                            item
                            style={{ marginTop: 16 }}
                        >
                            <Typography
                                variant='h6'
                            >
                                Radio Config
                            </Typography>
                        </Grid>
                        <Grid
                            spacing={2}
                            xs={12}
                            container
                            item
                        >
                            <GeneralTextField
                                width={4}
                                label='Dispatcher Radio Frequency'
                                placeholder='Save the frequency at which disptcher radio is set'
                                statePath={`selectedDepartment.dispatcherFrequency`}
                                value={selectedDepartment.dispatcherFrequency}
                                error={errors.selectedDepartment.dispatcherFrequency}
                                handleInputChange={this.handleInputChange}
                            />
                            <GeneralTextField
                                width={4}
                                label='Radio Channel'
                                placeholder="Department's radio channel..."
                                value={selectedDepartment?.radioChannelObject?.description ?? ''}
                                disabled={true}
                            />

                            <GeneralTextField
                                width={4}
                                label='Audio Dispatch Duration*'
                                value={selectedDepartment.dispatchDuration}
                                disabled={true}
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            container
                            item
                            style={{ marginTop: 16 }}
                        >
                            <Typography
                                variant='h6'
                            >
                                FirePITCH Status Alerts
                            </Typography>
                        </Grid>
                        <GeneralCheckboxField
                            gridStyle={{ display: 'flex', alignItems: 'center', margin: 0, marginTop: 8 }}
                            width={"auto"}
                            size='small'
                            label='Send alerts to Department?'
                            statePath={`selectedDepartment.alertDepartmentForFirepitch`}
                            value={selectedDepartment.alertDepartmentForFirepitch}
                            error={errors.selectedDepartment.alertDepartmentForFirepitch}
                            handleInputChange={this.handleInputChange}
                        />
                        {selectedDepartment.alertDepartmentForFirepitch ?
                            <Grid
                                xs={12}
                                container
                                item
                            >
                                <GeneralTextField
                                    width={8}
                                    format='phoneNumber'
                                    label='Phone number for FirePITCH status alerts'
                                    placeholder={`Enter a phone number to receive FirePITCH status alerts...`}
                                    statePath={`selectedDepartment.alertDepartmentForFirepitchContactNumber`}
                                    value={selectedDepartment.alertDepartmentForFirepitchContactNumber}
                                    error={errors.selectedDepartment.alertDepartmentForFirepitchContactNumber}
                                    handleInputChange={this.handleInputChange}
                                />
                            </Grid>
                            :
                            null
                        }
                        <Grid
                            xs={12}
                            container
                            item
                            style={{ marginTop: 16 }}
                        >
                            <Typography
                                variant='h6'
                            >
                                Configure Audio Tones
                            </Typography>
                            <Tooltip
                                style={{ marginLeft: 8 }}
                                title='Configure the radio tones that will trigger the recording to send to subscribers in your department directly from dispatch.'
                            >
                                <HelpIcon />
                            </Tooltip>
                        </Grid>
                        {selectedDepartment.tones.map((tone, index) =>
                            <Grid
                                key={'tones-' + index}
                                xs={12}
                                spacing={2}
                                item
                                container
                            >
                                <GeneralDropdownField
                                    autoFocus={true}
                                    options={ToneTypes}
                                    width={3}
                                    label='Tone'
                                    hideLabel={index !== 0}
                                    placeholder='Enter tone...'
                                    statePath={`selectedDepartment.tones.${index}.type`}
                                    value={selectedDepartment.tones[index].type}
                                    error={errors.selectedDepartment.tones[index]?.type}
                                    handleInputChange={this.handleDepartmentInputChange}
                                />
                                <GeneralTextField
                                    type="number"
                                    width={3}
                                    label='Frequency'
                                    hideLabel={index !== 0}
                                    placeholder='Frequency (Hz)'
                                    statePath={`selectedDepartment.tones.${index}.value`}
                                    value={selectedDepartment.tones[index].value}
                                    error={errors.selectedDepartment.tones[index]?.value}
                                    handleInputChange={this.handleFloatInputChange}
                                />
                                <GeneralTextField
                                    type="number"
                                    width={3}
                                    label='Variance'
                                    hideLabel={index !== 0}
                                    placeholder='Variance (Hz)'
                                    statePath={`selectedDepartment.tones.${index}.variance`}
                                    value={selectedDepartment.tones[index].variance}
                                    error={errors.selectedDepartment.tones[index]?.variance}
                                    handleInputChange={this.handleFloatInputChange}
                                />
                                <GeneralTextField
                                    type="number"
                                    width={2}
                                    label='Duration'
                                    hideLabel={index !== 0}
                                    placeholder='Duration (s)'
                                    statePath={`selectedDepartment.tones.${index}.length`}
                                    value={selectedDepartment.tones[index].length}
                                    error={errors.selectedDepartment.tones[index]?.length}
                                    handleInputChange={this.handleFloatInputChange}
                                />
                                <Grid
                                    item
                                    xs={1}
                                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                                >
                                    <IconButton
                                        aria-label='close'
                                        onClick={() => this.deleteToneField(index)}
                                        disabled={loaders.updateDepartment}
                                    >
                                        <RemoveIcon style={{ color: '#ff0000' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                onClick={this.addToneField}
                                disabled={loaders.updateDepartment}
                                color='primary'
                                variant='outlined'
                            >
                                {!selectedDepartment.tones.length ? 'Add a tone' : 'Add another tone'}
                            </Button>
                        </Grid>
                    </Grid>
                }
                {currentTab === 'firepitch' && !selectedDepartment.featuresPermission?.audioDispatches &&
                    <Grid
                        xs={12}
                        spacing={2}
                        container
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 32 }}
                    >
                        <UpgradeToPremium
                            isModal={false}
                            upgradeToPremiumText={'Automated dispatch and digital radio features are only available to premium departments.'}
                        />
                    </Grid>
                }
                { // Department Users
                    currentTab === 'users' &&
                    <DepartmentUsersTab
                        selectedDepartment={selectedDepartment}
                        info={info}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        sortedUsers={sortedUsers}
                        loaders={loaders}
                        selectedDepartmentUsers={selectedDepartmentUsers}
                        getUsers={this.getUsers}
                        handleModal={this.handleModal}
                        handleInputChange={this.handleInputChange}
                        resetSearch={this.resetSearch}
                        searchUsers={this.searchUsers}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleColumnHeaderClick={this.handleColumnHeaderClick}
                        accessToken={this.props.accessToken}
                    />
                }
                {currentTab !== 'users' &&
                    <Grid
                        spacing={2}
                        container
                        style={{ padding: 16 }}
                    >
                        <Grid
                            item
                            xs={12}
                            style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
                        >
                            <Button
                                onClick={this.updateDepartment}
                                color='secondary'
                                variant='contained'
                                fullWidth
                            >
                                {loaders.updateDepartment &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Update
                            </Button>
                        </Grid>

                    </Grid>
                }

            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(Department))



// move to separate file

const UserTypeTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
    },
})

const UserTypeTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        border: 0,
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-selected': {
            border: 0,
            '&:last-child': {
                border: 0
            }
        }
    }),
)


const TABS_USERS_TYPES = {
    department: 'department',
    userRegistrationRequest: 'departmentUserRegistrationRequests'
}

const DepartmentUsersTab = withSnackbar((props) => {
    const { info, pagination, limit, page, sortedColumn, sortOrder, sortedUsers, selectedDepartment, loaders,
        selectedDepartmentUsers, accessToken, getUsers,
        handleModal, handleInputChange, resetSearch, searchUsers, handleChangePage, handleChangeRowsPerPage, handleColumnHeaderClick
    } = props
    const [tabsUserType, setTabsUserType] = useState(TABS_USERS_TYPES.department)
    const [registrationRequestsCount, setRegistrationRequestsCount] = useState(0)
    const [getRegistrationRequestsCountLoading, setGetRegistrationRequestsCountLoading] = useState(0)

    const [showHandlePendingRegistrationDialog, setShowHandlePendingRegistrationDialog] = useState(false)
    const [pendingRegistrationRequestLoading, setPendingRegistrationRequestLoading] = useState(false)

    const getRegistrationRequestsCount = async () => {
        if (getRegistrationRequestsCountLoading) return
        setGetRegistrationRequestsCountLoading(true)
        try {
            const config = {
                headers: { Authorization: accessToken },
                params: { departmentId: selectedDepartment._id },
            }
            const response = await axios.get('/api/users/registration-requests-count', config)
            if (response && response.data && response.data.success) {
                setRegistrationRequestsCount(response.data.count)
            }
        } catch (error) {
            console.log(error)
        }
        setGetRegistrationRequestsCountLoading(false)
    }

    const handlePedingRegistrationRequest = (user) => {
        setShowHandlePendingRegistrationDialog(user)
    }

    const handleAccpetOrDeclineRegistrationRequest = async (action, userId) => {
        if (pendingRegistrationRequestLoading) return
        setPendingRegistrationRequestLoading(true)
        try {
            const { accessToken } = props
            const config = {
                headers: {
                    Authorization: accessToken
                }
            }
            const body = {
                action,
                departmentId: selectedDepartment._id,
            }
            await axios.post(`/api/users/${userId}/registration-request`, body, config)
            if (sortedUsers.length === 1) {
                // if there are not other pernding registration requests set the tab to users
                setTabsUserType(TABS_USERS_TYPES.department)
                setRegistrationRequestsCount(0)

            } else {
                getUsers()
            }
            OpenSnackBar(props.enqueueSnackbar, props.closeSnackbar, "Registration request processed successfully", "success")
        } catch (error) {
            OpenSnackBar(props.enqueueSnackbar, props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to process registration request", "error")
        }
        setPendingRegistrationRequestLoading(false)
    }

    useEffect(() => {
        getRegistrationRequestsCount()
    }, [])

    useEffect(() => {
        getUsers(tabsUserType)
    }, [tabsUserType])

    const loading = loaders.getUsers || getRegistrationRequestsCountLoading

    return (
        <>
            {showHandlePendingRegistrationDialog &&
                <UserRegistrationRequestDialog
                    open={showHandlePendingRegistrationDialog}
                    onClose={() => setShowHandlePendingRegistrationDialog(false)}
                    user={showHandlePendingRegistrationDialog}
                    onProcessRequest={handleAccpetOrDeclineRegistrationRequest}
                    loading={pendingRegistrationRequestLoading}
                />
            }
            {registrationRequestsCount > 0 &&
                <UserTypeTabs
                    value={tabsUserType}
                    onChange={(e, value) => setTabsUserType(value)}
                    variant='fullWidth'
                    aria-label="tabsUserType"
                >
                    <UserTypeTab label="Users" value={TABS_USERS_TYPES.department} style={{ opacity: 1 }} />
                    {registrationRequestsCount > 0 && <UserTypeTab label={`Registration Requests (+${registrationRequestsCount})`} value={TABS_USERS_TYPES.userRegistrationRequest} style={{ opacity: 1 }} />}
                </UserTypeTabs>
            }
            {!loading && <Grid
                xs={12}
                container
                style={{ display: 'flex', flex: 1, justifyContent: 'space-between', marginBottom: 8 }}
            >
                <Grid
                    item
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginLeft: 32 }}
                >
                    <Button
                        onClick={() => handleModal('createNewUser')}
                        color='primary'
                        variant='outlined'
                    >
                        <AddBoxIcon
                            style={{ marginRight: 8 }}
                        />
                        Add User
                    </Button>
                </Grid>
                <Grid
                    style={{ display: 'flex', marginRight: 32 }}
                    item
                >
                    <GeneralTextField
                        format="search"
                        gridStyle={{ width: 350 }}
                        placeholder='Search users...'
                        statePath={`info.search`}
                        value={info.search}
                        handleInputChange={handleInputChange}
                        resetSearch={resetSearch}
                        onSearch={searchUsers}
                    />
                </Grid>
            </Grid>
            }
            <TableContainer
                component={Paper}
                elevation={0}
                style={{
                    padding: 32,
                    paddingTop: 0,
                    borderRadius: 0,
                    marginBottom: 64,
                    display: 'flex',
                    flex: 1
                }}
            >
                <Table
                    aria-label='Users'
                >
                    <TablePagination
                        showAtTop={true}
                        total={pagination.total}
                        rowsPerPage={limit}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                First Name
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('firstName')}
                                    selected={sortedColumn === 'firstName'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>

                            <TableCell>
                                Last Name
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('lastName')}
                                    selected={sortedColumn === 'lastName'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>

                            <TableCell>
                                Email
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('email')}
                                    selected={sortedColumn === 'email'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>

                            <TableCell>
                                Phone
                                <ToggleIconButton
                                    onClick={() => handleColumnHeaderClick('phone')}
                                    selected={sortedColumn === 'phone'}
                                    sortOrder={sortOrder}
                                />
                            </TableCell>

                            <TableCell
                                align='right'
                            ></TableCell>
                            <TableCell
                                align='right'
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && sortedUsers?.map((user) =>
                            <TableRow
                                key={user._id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleModal('editUser', user)}
                            >
                                <TableCell
                                    component='th'
                                >
                                    {user.firstName}
                                </TableCell>
                                <TableCell
                                    component='th'
                                >
                                    {user.lastName}
                                </TableCell>
                                <TableCell
                                    component='th'
                                >
                                    {user.email}
                                </TableCell>
                                <TableCell
                                    component='th'
                                >
                                    {maskPhoneNumber(user.phone)}
                                </TableCell>
                                <TableCell
                                    align='right'
                                    width='10'
                                >
                                    <IconButton
                                        onClick={() => handleModal('editUser', user)}
                                        size='small'
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell
                                    align='right'
                                >
                                    {user.departmentPendingRegistrationRequests?.find(dept => dept.departmentId === selectedDepartment._id)?.departmentId ?
                                        <Button
                                            type="button"
                                            variant='contained'
                                            size='small'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handlePedingRegistrationRequest(user)
                                            }}
                                        >
                                            Pending
                                        </Button>
                                        : user.deactivated ?
                                            <Tooltip
                                                title='User was deactivated'
                                            >
                                                <div
                                                    style={{ width: 20, height: 20, background: '#ff0000', borderRadius: '50%' }}
                                                />
                                            </Tooltip>
                                            :
                                            (user.activated && user?.invites?.find(invite => String(invite.departmentId) === String(selectedDepartment._id))) ?
                                                <Tooltip
                                                    title={
                                                        Date.now() < new Date(user?.invites?.find(invite => String(invite.departmentId) === String(selectedDepartment._id)).expires)?.getTime() ?
                                                            'Invitation still not accepted'
                                                            :
                                                            'Invitation expired'
                                                    }
                                                >
                                                    <div
                                                        style={{ width: 20, height: 20, background: 'yellow', borderRadius: '50%' }}
                                                    />
                                                </Tooltip>
                                                :
                                                user.activated ?
                                                    <Tooltip
                                                        title='User is active'
                                                    >
                                                        <div
                                                            style={{ width: 20, height: 20, background: '#00b300', borderRadius: '50%' }}
                                                        />
                                                    </Tooltip>
                                                    :
                                                    !user.activated &&
                                                    <Tooltip
                                                        title='User has not completed account creation'
                                                    >
                                                        <div
                                                            style={{ width: 20, height: 20, background: 'yellow', borderRadius: '50%' }}
                                                        />
                                                    </Tooltip>
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                        {loading &&
                            <TableRow>
                                <TableCell
                                    style={{ paddingTop: 96, paddingBottom: 96 }}
                                    colSpan={6}
                                    align="center"
                                >
                                    <CircularProgress
                                        size={36}
                                    />

                                </TableCell>
                            </TableRow>
                        }
                        {(!selectedDepartmentUsers?.length) && !loading &&
                            <TableRow>
                                <TableCell
                                    style={{ paddingTop: 96, paddingBottom: 96 }}
                                    colSpan={6}
                                    align="center"
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        No users found in this department.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    <TablePagination
                        total={pagination.total}
                        rowsPerPage={limit}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Table>
            </TableContainer>
        </>
    )
})