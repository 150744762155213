import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    CircularProgress,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles
import ActionConfirmation from '../../general/ActionConfirmation';

const useStyles = makeStyles((theme) => ({ // Define styles
    dialogTitle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dialogContent: {
        dividers: true,
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        spacing: theme.spacing(2),
        gap: theme.spacing(2),
    },
    confirmButton: {
        color: theme.palette.primary.main, // Use theme color
    },
}));

const UserRegistrationRequestDialog = ({ open, onClose, user, onProcessRequest, loading }) => {
    const classes = useStyles(); // Use styles
    const [action, setAction] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleAction = (actionType) => {
        setAction(actionType);
        setConfirmOpen(true);
    };

    const confirmAction = async () => {
        setConfirmOpen(false);
        await onProcessRequest(action, user._id);
        onClose();
    };

    return (
        <>
            <Dialog
                onClose={!loading ? onClose : undefined}
                open={open}
                maxWidth="sm" // Adjust maxWidth as needed
                PaperProps={{ style: { flex: 1 } }} // Apply PaperProps for better styling
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography variant="h6">User Registration Request</Typography>
                </DialogTitle>
                <DialogContent dividers={true} className={classes.dialogContent}>
                    <Typography>Email: {user.email}</Typography>
                    <Typography>Phone: {user.phone}</Typography>
                    <Typography>Name: {user.firstName} {user.lastName}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container className={classes.buttonContainer}>
                        <Grid item>
                            <Button
                                onClick={() => handleAction('decline')}
                                disabled={loading}

                                style={{ backgroundColor: '#ff0000' }}
                            >
                                {loading && action === 'decline' && (
                                    <CircularProgress size={18} style={{ marginRight: 8 }} />
                                )}
                                Decline
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleAction('accept')}
                                disabled={loading}
                                variant='contained'
                                color='secondary'
                            >
                                {loading && action === 'accept' && (
                                    <CircularProgress size={18} style={{ marginRight: 8 }} />
                                )}
                                Accept
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <ActionConfirmation
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                title={`Are you sure you want to ${action} this request?`}
                onConfirmAction={confirmAction}
            />
        </>
    );
};

export default UserRegistrationRequestDialog;
