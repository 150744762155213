import { useState } from 'react'
// Material UI
import { Grid, Typography, CircularProgress, IconButton, Button, Link } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
// Components
import TypographyWithCopyText from '../../../general/TypographyWithCopyText'
import ActionConfirmation from '../../../general/ActionConfirmation'

const UserRegistrationCode = ({
    department, update, authenticatedUser, socket
}) => {
    const [userRegistrationCode, setUserRegistrationCode] = useState()
    const [renewUserRegistrationCodeLoading, setRenewUserRegistrationCodeLoading] = useState()
    const [generateUserRegistrationCodeLoading, setGenerateUserRegistrationCodeLoading] = useState()
    const [confirmRenewUserRegistrationCode, setConfirmRenewUserRegistrationCode] = useState()

    const renewUserRegistrationCode = async () => {
        if (renewUserRegistrationCodeLoading) return

        if (!socket) return
        if (authenticatedUser.type !== 'superAdmin') return

        setRenewUserRegistrationCodeLoading(true)

        socket.emit('renewUserRegistrationCode', department._id,
            (response) => {
                if (response.success) {
                    setUserRegistrationCode(response.userRegistrationCode)
                    setRenewUserRegistrationCodeLoading(false)
                }
                else setRenewUserRegistrationCodeLoading(false)
            }
        )
    }

    const generateUserRegistrationCode = async () => {
        if (generateUserRegistrationCodeLoading) return
        if (authenticatedUser.type !== 'superAdmin') return
        if (!socket) return

        setGenerateUserRegistrationCodeLoading(true)
        socket.emit('generateUserRegistrationCode', department._id,
            (response) => {
                if (response.success) {
                    setUserRegistrationCode(response.userRegistrationCode)
                    setGenerateUserRegistrationCodeLoading(false)
                }
                else setGenerateUserRegistrationCodeLoading(false)
            }
        )
    }

    const openConfirmRenewDepartmentApiKey = () => {
        setConfirmRenewUserRegistrationCode(true)
    }

    const closeConfirmRenewUserRegistrationCode = () => {
        setConfirmRenewUserRegistrationCode(false)
    }

    return (
        <>
            {department.featuresPermission.allowUserRegistrationByCode ?
                <Grid
                    container
                    xs={12}
                >
                    <Typography
                        variant='h6'
                        style={{ width: '100%', marginTop: 12 }}
                    >
                        Department User Registration Link
                    </Typography>
                    {authenticatedUser.type === 'superAdmin' && update &&
                        <>
                            {!userRegistrationCode ?
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 24, marginBottom: 24 }}
                                >
                                    <Typography
                                        variant='subtitle'
                                    >
                                        ****************
                                    </Typography>
                                    <IconButton
                                        onClick={generateUserRegistrationCode}
                                        size='small'
                                        style={{ marginLeft: 16 }}
                                    >
                                        {generateUserRegistrationCodeLoading ?
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                            :
                                            <VisibilityIcon />
                                        }
                                    </IconButton>

                                </div>
                                :
                                <div
                                    style={{ display: 'flex', flexDirection: 'column', marginTop: 24, marginBottom: 24 }}
                                >
                                    <TypographyWithCopyText
                                        text={`${process.env.NODE_ENV === 'development' ? "localhost:3000/user-registration/" : "https://console.firetext.net/user-registration/"}${userRegistrationCode}`}
                                        variant='subtitle'
                                        gutterBottom
                                    />
                                    <Button
                                        onClick={openConfirmRenewDepartmentApiKey}
                                        color='secondary'
                                        variant='contained'
                                        style={{ marginTop: 16 }}
                                    >
                                        {renewUserRegistrationCodeLoading &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginTop: 16, marginRight: 8 }}
                                            />
                                        }
                                        Renew User Registration Link
                                    </Button>
                                </div>
                            }
                        </>
                    }
                </Grid>
                :
                <Grid
                    xs={12}
                    spacing={2}
                    container
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 32, marginTop: 12, marginBottom: 12 }}
                >
                    <Typography>
                        {"User Registration Code can only be generated for departments with relevant permissions. Contact us for more information at "}
                        <Link
                            target='_blank'
                            href='https://firetext.net/support'
                            style={{ color: '#FFFFFF' }}
                            underline="always"
                        >
                            firetext.net
                        </Link>
                    </Typography>
                </Grid>
            }
            <ActionConfirmation
                open={confirmRenewUserRegistrationCode}
                onClose={closeConfirmRenewUserRegistrationCode}
                title="Are you sure you want to renew code for user registrations?"
                description='Renew the code only in case you are receiving spam registration requests for your department.'
                onConfirmAction={renewUserRegistrationCode}
            />
        </>
    )
}

export default UserRegistrationCode;
