// React
import React, { useEffect, useState } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import GeneralTextField from '../../general/TextField'
import GeneralDropdownField from '../../general/DropdownField'
import GeneralCheckboxField from '../../general/CheckboxField'
// Utilities
import DateTimePickerWrapper, { DATE_FORMAT } from '../../general/dateTimePicker/DateTimePicker'
import axios from 'axios'


// we splitted this component in two, the wrapper gets called in FireTextAnnouncement 
// screens, where as the latter modal component is rendered in dashboard for quickAccess
const CreateNewFiretextAnnouncementModalWrapper = (props) => {
    const { handleInputChange, createFiretextAnnouncement, firetextAnnouncement, isSuperAdmin, errors, loaders: propsLoaders, open, handleClose } = props

    const [state, setState] = useState({
        departments: [],
        loaders: {
            getDepartments: false
        }
    })

    const { departments, loaders } = state

    const getDepartments = async () => {
        const { accessToken } = props

        if (!loaders.getDepartments) {
            setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments', config)
                if (getDepartmentsResponse.data.success) {
                    const departments = getDepartmentsResponse.data.departments

                    setState(prevState => ({
                        departments: isSuperAdmin ? [{ name: 'Global Announcement', _id: 'global' }, ...departments] : departments.filter(dept => !!dept.featuresPermission.firetextAnnouncements),
                        loaders: { ...prevState.loaders, getDepartments: false },
                    }))
                }
                else {
                    setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: false } }))
                }
            } catch (error) {
                setState(prevState => ({
                    loaders: { ...prevState.loaders, getDepartments: false }
                }))
            }
        }
    }

    useEffect(() => {
        getDepartments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectedDepartment = departments?.find(dept => dept._id === firetextAnnouncement.departmentId)
    // we do !selected departments below to handle global announcement case
    const hasPushNotificationsPermission = firetextAnnouncement.departmentId === 'global' || selectedDepartment?.featuresPermission?.pushNotifications === true
    const hasEmailNotificationsPermission = firetextAnnouncement.departmentId === 'global' || selectedDepartment?.featuresPermission?.emailNotifications === true


    return (
        <CreateNewFiretextAnnouncementModal
            open={open}
            firetextAnnouncement={firetextAnnouncement}
            departments={departments}
            errors={errors}
            propsLoaders={propsLoaders}
            loaders={loaders}
            handleInputChange={handleInputChange}
            hasPushNotificationsPermission={hasPushNotificationsPermission}
            hasEmailNotificationsPermission={hasEmailNotificationsPermission}
            createFiretextAnnouncement={createFiretextAnnouncement}
            handleClose={handleClose}
        />
    )

}
export const CreateNewFiretextAnnouncementModal = (props) => {
    const { open, firetextAnnouncement, errors, departments, propsLoaders, loaders, hasPushNotificationsPermission,
        hasEmailNotificationsPermission, handleInputChange, createFiretextAnnouncement } = props

    useEffect(() => {
        const beforeunload = (e) => {
            e.preventDefault()
            e.returnValue = true
        }

        window.addEventListener("beforeunload", beforeunload)
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
        let shouldCloseModal = window.confirm(
            "Changes you made may not be saved. Are you sure you want to leave this page?"
        )
        if (!shouldCloseModal) return
        else props.handleClose()
    }

    return (
        <>
            <Prompt
                when={true}
                message={() => "Changes you made may not be saved. Are you sure you want to leave this page?"}
            />
            <Dialog
                onClose={!propsLoaders.createNewFiretextAnnouncement ? handleClose : undefined}
                open={open}
                maxWidth='md'
                PaperProps={{ style: { flex: 1 } }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant='h6'
                    >
                        Add New Firetext Announcement
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        disabled={propsLoaders.createNewFiretextAnnouncement}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{ minHeight: '500px' }}
                >
                    {loaders?.getDepartments ?
                        <Grid
                            item
                            xs={12}
                            style={{ height: '50vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
                        >
                            <CircularProgress
                                size={36}
                            />
                        </Grid>
                        :
                        <Grid
                            xs={12}
                            spacing={2}
                            container
                        >
                            {departments &&
                                <GeneralDropdownField
                                    width={12}
                                    options={departments
                                        ?.sort((a, b) => a.name.localeCompare(b.name))
                                        ?.map(department => ({
                                            value: department._id,
                                            text: `${department.name} ${department.agency ?? ''}`
                                        }))
                                    }
                                    format="autocomplete"
                                    label='Select department*'
                                    placeholder='Select a department...'
                                    statePath='firetextAnnouncement.departmentId'
                                    value={firetextAnnouncement.departmentId}
                                    error={errors?.firetextAnnouncement?.departmentId}
                                    handleInputChange={handleInputChange}
                                />
                            }
                            <GeneralTextField
                                autoFocus={true}
                                multiline={true}
                                row={3}
                                width={12}
                                label='Announcement *'
                                placeholder='Enter announcement...'
                                statePath={`firetextAnnouncement.text`}
                                value={firetextAnnouncement.text}
                                error={errors?.firetextAnnouncement?.text}
                                handleInputChange={handleInputChange}
                            />

                            <DateTimePickerWrapper
                                width={12}
                                label='Expires'
                                placeholder='Specify announcement duration...'
                                statePath={`firetextAnnouncement.expiresMilli`}
                                value={firetextAnnouncement.expiresMilli}
                                error={errors?.firetextAnnouncement?.expiresMilli}
                                handleInputChange={handleInputChange}
                                format={DATE_FORMAT.milliSeconds}
                            />

                            <Grid
                                xs={12}
                                container
                                item
                            >

                                <Typography
                                    variant='subtitle2'
                                    style={{ marginBottom: 8 }}
                                >
                                    Notification Channels
                                </Typography>

                                <Grid
                                    xs={12}
                                    container
                                    item
                                >
                                    {hasPushNotificationsPermission &&
                                        <GeneralCheckboxField
                                            width={12}
                                            size='small'
                                            label='Push Notification'
                                            statePath={`firetextAnnouncement.sendPushNotification`}
                                            value={firetextAnnouncement.sendPushNotification}
                                            error={errors?.firetextAnnouncement.sendPushNotification}
                                            handleInputChange={handleInputChange}
                                            loader={false}
                                        />
                                    }
                                    {hasEmailNotificationsPermission &&
                                        <GeneralCheckboxField
                                            gridStyle={{ marginTop: 8 }}
                                            width={12}
                                            size='small'
                                            label='Email Notification'
                                            statePath={`firetextAnnouncement.sendEmailNotification`}
                                            value={firetextAnnouncement.sendEmailNotification}
                                            error={errors?.firetextAnnouncement?.sendEmailNotification}
                                            handleInputChange={handleInputChange}
                                            loader={false}
                                        />
                                    }
                                    {!hasEmailNotificationsPermission && !hasPushNotificationsPermission &&
                                        <Typography
                                            variant='subtitle2'
                                            style={{ marginTop: 8 }}
                                        >
                                            No notification channels are enabled for this department.
                                        </Typography>
                                    }
                                </Grid>

                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={createFiretextAnnouncement}
                        color='secondary'
                        variant='contained'
                    >
                        {propsLoaders.createNewFiretextAnnouncement &&
                            <CircularProgress
                                size={18}
                                style={{ marginRight: 8 }}
                            />
                        }
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateNewFiretextAnnouncementModalWrapper;