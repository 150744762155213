import React from 'react'
import { Button, ButtonBase, Grid, Typography } from '@material-ui/core'
import MicIcon from '@material-ui/icons/Mic'

const PttInterface = ({ departmentName, audioEnabled, participants, handlePttTouchUp, handlePttTouchDown, destroyJanusInstance }) => {

    const unmutedParticipant = participants?.find(p => p.muted === false)

    return (
        <Grid
            container
            style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
            <Typography
                variant='h6'
            >
                {departmentName}
            </Typography>

            <Grid
                container
                justifyContent='center'
            >
                <ButtonBase
                    onMouseDown={handlePttTouchDown}
                    onMouseUp={handlePttTouchUp}
                    style={{ margin: 20 }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: 220,
                            width: 220,
                            borderRadius: 110,
                            backgroundColor: audioEnabled ? '#fb8c00' : '#ec3223',
                        }}
                    >
                        <MicIcon style={{ fontSize: 110 }} />
                    </Grid>
                </ButtonBase>
            </Grid>

            <Grid
                container
                justifyContent='center'
                alignContent='center'
            >
                {unmutedParticipant?.id ?
                    <Typography
                        style={{ fontWeight: 'bold' }}
                    >
                        {`${unmutedParticipant.display} is talking...`}
                    </Typography>
                    :
                    null
                }

            </Grid>

            {destroyJanusInstance ?
                <Grid
                    container
                    justifyContent='center'
                >
                    <Button
                        onClick={destroyJanusInstance}
                        color='secondary'
                        variant='contained'
                        style={{
                            margin: 12
                        }}
                    >
                        Disconnect
                    </Button>
                </Grid>
                :
                null
            }

            <Typography
                variant='h6'
                style={{ marginTop: 12, marginBottom: 8 }}
            >
                {`Participants (${participants.length ?? 0})`}
            </Typography>

            <Grid
                container
                style={{
                    margin: 12
                }}
            >
                {participants.map((p, i) => (
                    <Grid
                        key={i}
                        item
                        xs={4}
                    >
                        <Grid
                            key={p.id + "" + i}
                            container
                            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                        >
                            <Typography>
                                {p.display}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

        </Grid>
    )
}

export default PttInterface;
